import MainService from "@/services/MainService.js";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import msal from "vue-msal";
// import QrcodeVue from "qrcode.vue";


import PageOptions from '../../config/PageOptions.vue'

// const moment = require("moment");
// var crypto = require("crypto");

Vue.use(VueAxios, axios);
// Vue.use( axios);

Vue.use(msal, {
    auth: {
        clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
        tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
        redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
    },
});

export default {
    name: "LoginPage",
    data() {
        let ss = new MainService();
        return {
            ss: ss,
            loginRequest: {},
            errorBag: {},
            hash: "",
            size: 300,
            alias: "",
            channel: "",
            name: process.env.VUE_APP_TITLE,
            persona:{},
            personaviatico:{},
            planillaviatico:{},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            isLoadingFile:false          
            
        };
    },
    components: {
        // QrcodeVue,
    },
    props: {
        msg: String,
    },

    created() {
		PageOptions.pageEmpty = true;
	},
	beforeRouteLeave (to, from, next) {
		PageOptions.pageEmpty = false;
		next();
	},
    
    methods: {
        printPersonaViatico() {
            this.isLoadingFile = true;
            this.planillaviatico.ci = this.persona.CI
            if(!this.planillaviatico.ci){
                this.$swal({
                    title: "Debe introducir su código asignado",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    cancelButtonColor: '#d33',
                })
                this.isLoadingFile = false;
                return;
            }
            //console.log(this.planillaviatico.ci, 'ESTO VIAJ FINALMNETE');
            this.ss.imprimirPersonaViaticoDos(this.planillaviatico.ci).then(
                (result) => {
                    let response = result.data;
                    // window.open(this.rutaApp+response.data.url);
                    if(response.success){
                        this.$swal({
                            title: "Se envió la Planilla de Asignacion de Recursos Económicos a su CORREO ELÉTRONICO, Reviselo Por Favor.",
                            icon: "info",
                            buttons: true,
                            dangerMode: true,
                            cancelButtonColor: '#d33',
                        })
                        this.isLoadingFile = false;
                        return;
                    }

                    console.log(response, "Se imprimio Reporte");
                }
            ).catch(error => {
                console.log(error);
                    this.$swal({
                        title: "<class='row'><h4>Ocurrio un error vuelva a intentarlo,</h4> <br> 1.- Verifique bien su número de Carnet. <br> 2.- Verifique si usted cuenta con viático.",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                        cancelButtonColor: '#d33',
                    })
                    this.isLoadingFile = false;
                
            });
        },

        // consultaPersonaViatico() {
        //     this.personaviatico= {};
        //     this.$refs['frm-persona'].show();
        // },

        cancel() {
            this.personaviatico ={},
            this.$refs['frm-persona'].hide();
        },

        consultaPersonaViatico() {
            this.isLoading = true;
            console.log(this.persona.ci);
            
            if (!this.persona.ci) {
                this.$swal({
                    title: "Debe introducir su Cédula de Identidad",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    cancelButtonColor: '#d33',
                });
                this.isLoading = false;
                return;
            }
            
            this.ss.showConsultaPersonaViatico(this.persona.ci)
                .then((result) => {
                    let response = result.data;
                    this.msgpersona = '';
                    this.msgviatico = '';
                    //console.log(result.data, 'inicio ');
                    this.msgpersona = result.data.msg;
                    //console.log(this.msgpersona,' este mensaje llega de persona');
                    if (response.success) {
                        this.personaviatico = response.data;
                        this.persona = response.persona;
                        this.$refs['frm-persona'].show();
                    } else {
                        this.$bvToast.toast(
                            this.msgpersona,
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        );
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.error("Error al consultar persona:", error);
                    this.$bvToast.toast(
                        'Error al consultar la persona. Por favor, inténtelo nuevamente más tarde.',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    );
                    this.isLoading = false;
                });
        }
    },
};
