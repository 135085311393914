import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import moment from 'moment'

export default {
    name: "ServicioPersonalPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ServicioPersonalPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexPersona(),
                data: function (d) {
                    d.servicios = 1;
                },
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición' },
                { data: 'Especialidad', name: 'e.Especialidad', title: 'Especialidad' },
                { data: 'Grado', name: 'g.Grado', title: 'Grado' },
                { data: 'Arma', name: 'a.Arma', title: 'Arma' },
                { data: 'CodMinDef', name: 'p.CodMinDef', title: 'CodMinDef' },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'CI', name: 'p.CI', title: 'CI' },
                { data: 'FechaNacimiento', name: 'p.FechaNacimiento', title: 'Fecha Nacimiento',  render: function(data,type ,row)
                {
                    return moment(row.FechaNacimiento).format('DD/MM/YYYY')
                }},                
                { data: 'Sexo', name: 's.Sexo', title: 'Genero' },
                { data: 'email', name: 'p.email', title: 'Correo' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxDestino: {
                "url": ss.indexServicioPersonalDestino(),
                headers: ss.getToken(),
                data: function (d) {
                    d.persona;
                    }
            },
            columnsDestino: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'Persona', title: 'Persona' },
                { data: 'id', name: 'id', title: 'id' },
                { data: 'SituacionConcepto', name: 'SituacionConcepto', title: 'Situacion Concepto' },
                { data: 'Tipo', name: 'Tipo', title: 'Tipo Doc.' },
                { data: 'Nro', name: 'Nro', title: 'Nro. Doc.' },
                { data: 'FechaDocumento', name: 'FechaDocumento', title: 'Fecha Doc.', render: function(data,type ,row)
                {
                    return moment(row.FechaDocumento).format('DD/MM/YYYY')
                }},  
                { data: 'FechaVigencia', name: 'FechaVigencia', title: 'Fecha Vig.', render: function(data,type ,row)
                {
                    return moment(row.FechaVigencia).format('DD/MM/YYYY')
                }},  
                { data: 'DestinoActual', name: 'DestinoActual', title: 'DestinoActual' },
                { data: 'EstadoDocumento', name: 'EstadoDocumento', title: 'EstadoDocumento' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rols: [],
            //aca tendria q colocar para cado uno??
            //si para que no salga esos errores c
            //porq digamos un ejemplo

            permanente1:'4',
            eventual:'7',
            altas:'9',
            asignacion:'14',
            asignacion1:'13',
            letras:'16',
            conclusion:'21',
            bajas:'30',
            ascenso:'36',
            varios:'41',
            destino:'44',
            estadodocumentos: [],
            serviciopersonals: [],
            situacions: [],
            isLoading: false,
            isLoadingFile: false,
            hasRepart:false,
            hasGrado:false,
            reparticiones: [],
            grados: [],
            sexos: [],
            familiar: {},
            familiares: [],
            familiarper: {},
            tipoFamiliares: [],
            profesiones: [],

            placeholderReparticion:"Buscar Destino....",
            placeholderGrado:"Buscar Grado...",
            placeholderCargo:"Buscar Cargo...",
            selectedAuxiliar: null,
            selectedNuevoGrado: null,
            selectedCargo: null,
            selectedReparticionNueva: null,
            selectedReparticionNuevaMindef: null,
            selectedReparticionTemporal: null,
            selectedReparticionLetra: null,
            selectedReparticionAnterior: null,

            searchReparticion:'',
            searchGrado:'',
            searchCargo:'',
            cargos: [],
            moment: moment,
            errorBag: {},
            password: {},
            serviciopersonal: {},
            search: '',
            seccion: 0,
            codigoConcepto: '001',
            documento: [],
            persona: {},
            
            permanenteFields:[
                'Nro',
                {key:'SituacionConcepto',label:'Tipo Situación',sortable:true}, 
                {key:'Descripcion',label:'Descripción',sortable:true}, 
                {key:'FechaVigencia',label:'Fecha Vigencia',sortable:true}, 
                {key:'Tipo',label:'Tipo de Doc.',sortable:true}, 
                {key:'Especialidad',label:'Especialidad',sortable:true}, 
                'Detalles'],
            eventualFields: [
                'Nro',
                {key:'SituacionConcepto',label:'Tipo Situación',sortable:true}, 
                {key:'Descripcion',label:'Descripción',sortable:true}, 
                {key:'FechaVigencia',label:'Fecha Vigencia',sortable:true}, 
                {key:'Tipo',label:'Tipo de Doc.',sortable:true}, 
                {key:'Especialidad',label:'Especialidad',sortable:true}, 
                {key:'FechaBaja',label:'Fecha Baja',sortable:true}, 
                {key:'FechaAlta',label:'Fecha Alta',sortable:true}, 
                'Detalles'],
            altasFields: [
                'Nro',
                {key:'SituacionConcepto',label:'Tipo Situación',sortable:true}, 
                {key:'Descripcion',label:'Descripción',sortable:true}, 
                {key:'FechaVigencia',label:'Fecha Vigencia',sortable:true}, 
                {key:'Tipo',label:'Tipo de Doc.',sortable:true}, 
                {key:'Especialidad',label:'Especialidad',sortable:true}, 
                'Detalles'], 
            asignacionFields: [
                'Nro',
                {key:'SituacionConcepto',label:'Tipo Situación',sortable:true}, 
                {key:'Descripcion',label:'Descripción',sortable:true}, 
                {key:'Tipo',label:'Tipo de Doc.',sortable:true}, 
                {key:'Especialidad',label:'Especialidad',sortable:true}, 
                'Detalles'], 
            letrasFields: [
                'Nro',
                {key:'SituacionConcepto',label:'Tipo Situación',sortable:true}, 
                {key:'Descripcion',label:'Descripción',sortable:true}, 
                {key:'Tipo',label:'Tipo de Doc.',sortable:true}, 
                {key:'Especialidad',label:'Especialidad',sortable:true}, 
                'Detalles'],
            conclusionFields: [
                'Nro',
                {key:'SituacionConcepto',label:'Tipo Situación',sortable:true}, 
                {key:'Descripcion',label:'Descripción',sortable:true}, 
                {key:'Tipo',label:'Tipo de Doc.',sortable:true}, 
                {key:'Especialidad',label:'Especialidad',sortable:true}, 
                'Detalles'],
            bajasFields: [
                'Nro',
                {key:'SituacionConcepto',label:'Tipo Situación',sortable:true}, 
                {key:'Descripcion',label:'Descripción',sortable:true}, 
                {key:'Tipo',label:'Tipo de Doc.',sortable:true}, 
                {key:'Especialidad',label:'Especialidad',sortable:true}, 
                'Detalles'],
            ascensoFields:[
                'Nro',
                {key:'SituacionConcepto',label:'BONO',sortable:true}, 
                {key:'Descripcion',label:'Descripción',sortable:true}, 
                {key:'Tipo',label:'Tipo de Doc.',sortable:true}, 
                {key:'Especialidad',label:'Especialidad',sortable:true}, 
                {key:'Auxiliar',label:'Grado Anterior',sortable:true}, 
                {key:'NuevoGrado',label:'Nuevo Grado',sortable:true}, 
                'Detalles'],   
            variosFields:[
                'Nro',
                {key:'SituacionConcepto',label:'BONO',sortable:true}, 
                {key:'Descripcion',label:'Descripción',sortable:true}, 
                {key:'Tipo',label:'Tipo de Doc.',sortable:true}, 
                {key:'Especialidad',label:'Especialidad',sortable:true}, 
                'Detalles'],           
            destinoFields:[
                'Nro',
                //{key:'Persona',label:'Nombre',sortable:true}, 
                {key:'Descripcion',label:'Descripción',sortable:true}, 
                {key:'DestinoActual',label:'Destino Actual',sortable:true}, 
                {key:'Cargo',label:'Cargo',sortable:true}, 
                'Tipo', 
                'Detalles']            
        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.serviciopersonal.Archivo = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        
        onChangeSituacionConcepto(evento){
            this.serviciopersonal.SituacionConcepto = evento.target.value;
            for(let element of this.situacions){
                if(element.id == evento.target.value){
                    this.codigoConcepto = element.Codigo;
                    console.log(this.codigoConcepto);
                }
            }
        },
        getTipoFamiliar() {
            this.ss.listTipoFamiliar().then(
                (result) => {
                    let response = result.data;
                    this.tipoFamiliares = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getProfesion() {
            this.ss
              .listProfesion()
              .then((result) => {
                let response = result.data;
                this.profesiones = response.data;
              })
              .catch((error) => {
                console.log(error);
              });
          },
        getSexo() {
            this.ss.listSexo().then(
                (result) => {
                    let response = result.data;
                    this.sexos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFamiliar() {
            this.familiarper.id = this.persona.CodigoPersona
            this.ss.listFamiliarIndividual(this.familiarper).then(
                (result) => {
                    let response = result.data;
                    this.familiares = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDocumento(grupo) {
            this.seccion=grupo;
            //console.log('estoy aca', grupo, 'y la persona es', this.persona.id);
            this.ss.listServicioPersonal({params:{'grupo':grupo,'persona':this.persona.id}}).then(
                (result) => {
                    let response = result.data;
                    this.documento = response.data;
                    this.getSituacionConcepto();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCargo() {
            this.ss.listCargo().then(
                (result) => {
                    let response = result.data;
                    this.cargos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoDocumento() {
            this.ss.listEstadoDocumento().then(
                (result) => {
                    let response = result.data;
                    this.estadodocumentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getSituacionConcepto() {
            this.ss.listSituacionConcepto({params:{'grupo':this.seccion}}).then(
                (result) => {
                    let response = result.data;
                    this.situacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newServicioPersonal() {
            this.serviciopersonal = {};
            this.$refs['frm-serviciopersonal'].show();
            // console.log("hhhhola")
        },
        showServicioPersonal(id) {
            this.isLoading=true;
            this.ss.showServicioPersonal(id).then(
                (result) => {
                    let response = result.data;
                    this.serviciopersonal = response.data;
                    this.$refs['view-serviciopersonal'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editServicioPersonal() {
            this.$refs['frm-serviciopersonal'].show();
            this.$refs['view-serviciopersonal'].hide();
        },
        cancelServicioPersonal() {
            if (this.serviciopersonal.id) {
                this.$refs['view-serviciopersonal'].show();
            }
            this.$refs['frm-serviciopersonal'].hide();
        },
        aceptarPersona() {
            this.$refs['view-persona'].hide();
        },
        saveServicioPersonal() {
            if(this.seccion === 10){
                this.serviciopersonal.Persona = this.persona.id;
                this.serviciopersonal.Grado = this.persona.Grado;
                this.serviciopersonal.AnteriorDestino = this.persona.Reparticion;
                this.ss.storeServicioPersonal(this.serviciopersonal).then(
                    (result) => {
                        console.log(result);
                        let response = result.data;
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['frm-serviciopersonal'].hide();
                        this.$refs.uno.refresh();
                        this.getDocumento(this.seccion);
                        this.ss.storeDestinoHistorial(this.serviciopersonal);
                        (result) => {
                            console.log(result,'de destino');
                            let response = result.data;
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )}
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        console.log(error.response.data);
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
            } else {
                    this.serviciopersonal.Persona = this.persona.id;
                    this.ss.storeServicioPersonal(this.serviciopersonal).then(
                        (result) => {
                            console.log(result);
                            let response = result.data;
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            //this.$refs['view-consulta'].show(); //para volver al modal
                            console.log(response);
                            this.$refs['frm-serviciopersonal'].hide();
        
                            this.$refs.uno.refresh();
                        // this.$refs['datatable-persona'].reload();
                        this.getDocumento(this.seccion);
                        })
                        .catch((error) => {
                            this.errorBag = error.response.data.errors;
                            console.log(error.response.data);
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
            }
        },
        deleteServicioPersonal() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyServicioPersonal(this.serviciopersonal)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-serviciopersonal'].hide();
                                this.$refs['datatable-persona'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-serviciopersonal'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-serviciopersonal'].show();
        },
        changePassword() {
            this.password.ServicioPersonal = this.serviciopersonal.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-serviciopersonal'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        filterTable(row, filter) {
            console.log('datos filtro',row,filter);
            if (filter==4) {//caso permanentes
                if (row.SituacionConcepto == 1 || row.SituacionConcepto == 2 || row.SituacionConcepto == 3 || row.SituacionConcepto == 4 || row.SituacionConcepto == 5) { //cuales son sus hijos? esos
                    return true;
                } else {
                    return false;
                  }
            } if  (filter==7) { //eventuales
                    if (row.SituacionConcepto == 6 || row.SituacionConcepto == 7 || row.SituacionConcepto == 8) { 
                        return true;
                    } else {
                        return false;
                      }
            }
            if  (filter==9) { //altas
                if (row.SituacionConcepto == 9 || row.SituacionConcepto == 10 || row.SituacionConcepto == 11 || row.SituacionConcepto == 12 || row.SituacionConcepto == 51 || row.SituacionConcepto == 52) { 
                    return true;
                } else {
                    return false;
                  }
            } 
            if  (filter==14) { //aSIGNACION
                if (row.SituacionConcepto == 13 || row.SituacionConcepto == 14 || row.SituacionConcepto == 15 || row.SituacionConcepto == 59) { 
                    return true;
                } else {
                    return false;
                  }
            }
            if  (filter==16) { //LETRAS
                if (row.SituacionConcepto == 16 || row.SituacionConcepto == 17 || row.SituacionConcepto == 18 || row.SituacionConcepto == 19 || row.SituacionConcepto == 20 || row.SituacionConcepto == 46 || row.SituacionConcepto == 54 || row.SituacionConcepto == 60) { 
                    return true;
                } else {
                    return false;
                  }
            }
            if  (filter==21) { //CONCLUSION
                if (row.SituacionConcepto == 22 || row.SituacionConcepto == 23 || row.SituacionConcepto == 24 || row.SituacionConcepto == 25 || row.SituacionConcepto == 26 || row.SituacionConcepto == 27 || row.SituacionConcepto == 28 || row.SituacionConcepto == 48 ) { 
                    return true;
                } else {
                    return false;
                  }
            } 
            if  (filter==30) { //BAJAS
                if (row.SituacionConcepto == 29 || row.SituacionConcepto == 30 || row.SituacionConcepto == 35 || row.SituacionConcepto == 31 || row.SituacionConcepto == 34 || row.SituacionConcepto == 58  || row.SituacionConcepto == 57  || row.SituacionConcepto == 32 || row.SituacionConcepto == 33 ) { 
                    return true;
                } else {
                    return false;
                  }
            } 
            if  (filter==36) { //aSCENCOS
                if (row.SituacionConcepto == 36 || row.SituacionConcepto == 37 || row.SituacionConcepto == 53 ) { 
                    return true;
                } else {
                    return false;
                  }
            }
            if  (filter==41) { //VARIOS
                if (row.SituacionConcepto == 41 || row.SituacionConcepto == 42  ) { 
                    return true;
                } else {
                    return false;
                  }
            } 
            if  (filter==44) { //DESTINO
                if (row.SituacionConcepto == 43 || row.SituacionConcepto == 44 || row.SituacionConcepto == 45 || row.SituacionConcepto == 47 || row.SituacionConcepto == 55 || row.SituacionConcepto == 56  ) { 
                    return true;
                } else {
                    return false;
                  }
            }    
            else {
                return false;
            }
           
          },
        showPersona(id) {
            this.isLoading=true;
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.persona = response.data;
                    this.$refs['view-persona'].show();
                    this.isLoading=false;
                    this.getDocumento(1);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        draw() {
            window.$('.btn-datatable-Persona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersona(data);
            });
        },
        newPersonaFamiliar() {
        this.familiar = {};
        this.$refs['frm-familiar'].show();
        },
        saveFamiliar() {
            console.log(this.persona.CodigoPersona)
            this.familiar.Persona = this.persona.CodigoPersona;
            this.ss
              .storeFamiliar(this.familiar)
              .then((result) => {
                console.log(result);
                let response = result.data;
                this.$bvToast.toast(response.msg, {
                  title: 'Correcto',
                  variant: 'success',
                  autoHideDelay: 5000,
                });
                this.$refs['frm-familiar'].hide();
              })
              .catch((error) => {
                //   this.errorBag = error.response.data.errors;
                console.log(error);
                this.$bvToast.toast(
                  'Problema al Guardar el Registro, favor verificar los Datos',
                  {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                  }
                );
              });
          },
    },
    components: {
        dataTable,
        Loader
    },


    //aqui **


    cleanPerson() {
        this.hasRepart = false;
    },

    showServicioPersonal(id) {
        this.serviciopersonal={};
        
        //this.personaviaticofamiliar=[],
        this.isLoading=true;
        this.hasRepart = false;
        this.ss.showServicioPersonal(id).then(
            (result) => {
                let response = result.data;
                this.serviciopersonal = response.data;
                if(this.serviciopersonal.reparticion){
                    this.hasRepart = true;
                }
                this.$refs['view-serviciopersonal'].show();
                this.isLoading=false;
                    this.ss.showPersonaPorCodigo(this.serviciopersonal.Reparticion).then(
                    (result2) =>{
                        let response2 = result2.data;
                        console.log(response2);
                        //htis.actualizarPersonaFamiliar(response2);
                    }
                    )
              //  this.actualizarPersonaFamiliar(this.personaviatico.Persona);
            }
        ).catch(error => {
            console.log(error);
            this.isLoading=false;
        });
    },
   

    //aqui **
    watch: {
        searchReparticion(q) {
            this.ss.select2Reparticion(q).then((res) => {
                if (res.data.success) {
                   
                    this.reparticiones = res.data.items;
                                   
                } else {
                    this.reparticiones = [];
                }
            })
        },
        selectedReparticionNueva(reparticion){
            console.log(reparticion)
            this.serviciopersonal.ReparticionNueva = reparticion.id;
           // this.showPersonaFamiliar(persona)
        },
        selectedReparticionNuevaMindef(reparticion){
            console.log(reparticion)
            this.serviciopersonal.ReparticionNuevaMindef = reparticion.id;
           // this.showPersonaFamiliar(persona)
        },
        selectedReparticionTemporal(reparticion){
            console.log(reparticion)
            this.serviciopersonal.ReparticionTemporal = reparticion.id;
           // this.showPersonaFamiliar(persona)
        },
        selectedReparticionLetra(reparticion){
            console.log(reparticion)
            this.serviciopersonal.ReparticionLetra = reparticion.id;
           // this.showPersonaFamiliar(persona)
        },
        selectedReparticionAnterior(reparticion){
            console.log(reparticion)
            this.serviciopersonal.DestinoAnterior = reparticion.id;
           // this.showPersonaFamiliar(persona)
        },
        searchGrado(q) {
            this.ss.select2Grado(q).then((res) => {
                if (res.data.success) {
                   
                    this.grados = res.data.items;
                                   
                } else {
                    this.grados = [];
                }
            })
        },
        selectedAuxiliar(grado){
            console.log(grado)
            this.serviciopersonal.Auxiliar = grado.id;
        },
        selectedNuevoGrado(grado){
            console.log(grado)
            this.serviciopersonal.NuevoGrado = grado.id;
        },
        searchCargo(q) {
            this.ss.select2Cargo(q).then((res) => {
                if (res.data.success) {
                   
                    this.cargos = res.data.items;
                                   
                } else {
                    this.cargos = [];
                }
            })
        },
        selectedCargo(cargo){
            this.serviciopersonal.CargoDestino = cargo.id;
        },
        
    },
   
    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getEstadoDocumento();
            this.getSexo();
            this.getTipoFamiliar();
            this.getProfesion();
            //this.getSituacionConcepto();
        } 
    }
};
