import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ReporteSPPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ReporteSPPage",
            ss: ss,
            auth: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            variables:{},
            conceptosp:{},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            situacionconcepto:{},
            // cuadros:{},
            cuadros:{
                Daen:false,
                Dem:false,
                Depss:false,
                Dim:false,
                Magister:false,
                Frontera:false,
                Profesional:false,
                ProLibro:false,
                incorpInstituosMil:false,
                incorpPersCivil:false,
                reincorpPersCivil:false,
                reincorpPersMil:false,
                incorpArt114:false,
                incorpArt118:false,
                Lactancia:false,
                Natalidad:false,
                Mortalidad:false,
                Prenatal:false,
                LetraA:false,
                LetraB:false,
                LetraC:false,
                LetraD:false,
                LetraEjustMil:false,
                LetraEjustOrd:false,
                AplicArt87:false,
                AplicArt114:false,
                cLetraB:false,
                cLetraC:false,
                cLetraD:false,
                cLetraEjustMil:false,
                cLetraEjustOrd:false,
                cBonoProLibro:false,
                cCursoMil:false,
                cDestinoMil:false,
                ExoneracionCargo:false,
                Fallecimiento:false,
                Jubilacion:false,
                RetiroObligatorio:false,
                RetiroVoluntario:false,
                BajaTemporalLicenciaMaxima:false,
                BajaLicenciaTemporal:false,
                oga:false,
                PostOga:false,
                Postumo:false,
                CambioEscalafon:false,
                ModificacionDatos:false,
                ogd:false,
                PostOgd:false,
                Radicatoria:false,
                CursoMil:false,
                DestTemp:false,
                ReservaAct:false,
            },
            cuadrosba:{},
        };
    },
    methods: {

        getConceptoSP() {
            this.ss.listConceptoSP().then(
                (result) => {
                    let response = result.data;
                    this.conceptosp = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getSituacioConcepto() {
            this.ss.listSituacionConcepto().then(
                (result) => {
                    let response = result.data;
                    this.situacionconcepto = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        enviar() {
            console.log(this.variables)
            this.ss.generaReporte(this.variables).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    // this.$bvToast.toast(
                    //     response.msg,
                    //     {
                    //         title: 'Correcto',
                    //         variant: 'success',
                    //         autoHideDelay: 5000
                    //     }
                    // )
                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        exportexcel() {
            this.ss.exportexcel().then(
                (result) => {
                    let response = result.data
                    console.log(response)
                    var link = document.createElement("a");
                        // If you don't know the name or want to use
                        // the webserver default set name = ''
                        link.setAttribute('download', 'reporte.xlsx');
                        link.href = response;
                        link.target ='_blank'
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        setReset() {
            this.variables = {};
        },
        pdfpermanente(){
            console.log(this.variables);
            this.ss.printPermanenteGral(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        pdfEventual(){ 
            console.log(this.variables);           
            this.ss.printEventualGral(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        pdfAltas(){ 
            console.log(this.variables);           
            this.ss.printAltasGral(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        pdfAsignacion(){
            console.log(this.variables);            
            this.ss.printAsignacionGral(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        pdfLetras(){  
            console.log(this.variables);          
            this.ss.printLetrasGral(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        pdfConcluciones(){
            console.log(this.variables);            
            this.ss.printConclucionesGral(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        pdfBajas(){ 
            console.log(this.variables);           
            this.ss.printBajasGral(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        pdfAscensos(){ 
            console.log(this.variables);           
            this.ss.printAscensosGral(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        pdfVarios(){
            console.log(this.variables);            
            this.ss.printVariosGral(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        pdfDestinos(){  
            console.log(this.variables);          
            this.ss.printDestinosGral(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        pdfCuadroAlteraciones(){ 
            console.log(this.variables);           
            this.ss.printCuadroAlteracionesGral(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        console.log/(response);
                        // var urlFile = response.data;
                        // var dir = this.rutaApp + urlFile.url;
                        // window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                })
        },
        ModalDaen(){
            this.$refs['modalDaen'].show();
        },
        cancelDaen() {
            this.$refs['modalDaen'].hide();
        },
        printPDaen(){            
            this.ss.printPDaen(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        ModalDem(){
            this.$refs['modalDem'].show();
        },
        cancelDem() {
            this.$refs['modalDem'].hide();
        },
        printPDem(){            
            this.ss.printPDem(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        ModalDepss(){
            this.$refs['modalDepss'].show();
        },
        cancelDepss() {
            this.$refs['modalDepss'].hide();
        },
        printPDepss(){            
            this.ss.printPDepss(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        ModalDim(){
            this.$refs['modalDim'].show();
        },
        cancelDim() {
            this.$refs['modalDim'].hide();
        },
        printPDim(){            
            this.ss.printPDim(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        ModalMagister(){
            this.$refs['modalMagister'].show();
        },
        cancelMagister() {
            this.$refs['modalMagister'].hide();
        },
        printPMagister(){            
            this.ss.printPMagister(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalFrontera(){
            this.$refs['modalFrontera'].show();
        },
        cancelFrontera() {
            this.$refs['modalFrontera'].hide();
        },
        printEFrontera(){            
            this.ss.printEFrontera(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalProfesional(){
            this.$refs['modalProfesional'].show();
        },
        cancelProfesional() {
            this.$refs['modalProfesional'].hide();
        },
        printEProfesional(){           
            this.ss.printEProfesional(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalProlibro(){
            this.$refs['modalProlibro'].show();
        },
        cancelProlibro() {
            this.$refs['modalProlibro'].hide();
        },
        printEProlibro(){            
            this.ss.printEProlibro(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalIniimm(){
            this.$refs['modalIniimm'].show();
        },
        cancelIniimm() {
            this.$refs['modalIniimm'].hide();
        },
        printAIniimm(){            
            this.ss.printAIniimm(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalInpc(){
            this.$refs['modalInpc'].show();
        },
        cancelInpc() {
            this.$refs['modalInpc'].hide();
        },
        printAInpc(){           
            this.ss.printAInpc(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalReipc(){
            this.$refs['modalReipc'].show();
        },
        cancelReipc() {
            this.$refs['modalReipc'].hide();
        },
        printAReipc(){           
            this.ss.printAReipc(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalReipm(){
            this.$refs['modalReipm'].show();
        },
        cancelReipm() {
            this.$refs['modalReipm'].hide();
        },
        printAReipm(){            
            this.ss.printAReipm(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalIncorporacion114(){
            this.$refs['modalIncorporacion114'].show();
        },
        cancelIncorporacion114() {
            this.$refs['modalIncorporacion114'].hide();
        },
        printAIncorporacion114(){            
            this.ss.printAIncorporacion114(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalIncorporacion118(){
            this.$refs['modalIncorporacion118'].show();
        },
        cancelIncorporacion118() {
            this.$refs['modalIncorporacion118'].hide();
        },
        printAIncorporacion118(){            
            this.ss.printAIncorporacion118(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalLactancia(){
            this.$refs['modalLactancia'].show();
        },
        cancelLactancia() {
            this.$refs['modalLactancia'].hide();
        },
        printAFLactancia(){
            this.ss.printAFLactancia(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalNatalidad(){
            this.$refs['modalNatalidad'].show();
        },
        cancelNatalidad() {
            this.$refs['modalNatalidad'].hide();
        },
        printAFNatalidad(){
            this.ss.printAFNatalidad(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalMortalidad(){
            this.$refs['modalMortalidad'].show();
        },
        cancelMortalidad() {
            this.$refs['modalMortalidad'].hide();
        },
        printAFMortalidad(){
            this.ss.printAFMortalidad(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalPrenatal(){
            this.$refs['modalPrenatal'].show();
        },
        cancelPrenatal() {
            this.$refs['modalPrenatal'].hide();
        },
        printAFPrenatal(){
            this.ss.printAFPrenatal(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalLactanciaConPaquete(){
            this.$refs['modalLactanciaConPaquete'].show();
        },
        cancelLactanciaConPaquete() {
            this.$refs['modalLactanciaConPaquete'].hide();
        },
        printAFLactanciaConPaquete(){
            this.ss.printAFLactanciaConPaquete(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalPrenatalConPaquete(){
            this.$refs['modalPrenatalConPaquete'].show();
        },
        cancelPrenatalConPaquete() {
            this.$refs['modalPrenatalConPaquete'].hide();
        },
        printAFPrenatalConPaquete(){
            this.ss.printAFPrenatalConPaquete(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalLetraA(){
            this.$refs['modalLetraA'].show();
        },
        cancelLetraA() {
            this.$refs['modalLetraA'].hide();
        },
        printLLetraA(){            
            this.ss.printLLetraA(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalLetraB(){
            this.$refs['modalLetraB'].show();
        },
        cancelLetraB() {
            this.$refs['modalLetraB'].hide();
        },
        printLLetraB(){            
            this.ss.printLLetraB(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalLetraC(){
            this.$refs['modalLetraC'].show();
        },
        cancelLetraC() {
            this.$refs['modalLetraC'].hide();
        },
        printLLetraC(){            
            this.ss.printLLetraC(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalLetraD(){
            this.$refs['modalLetraD'].show();
        },
        cancelLetraD() {
            this.$refs['modalLetraD'].hide();
        },
        printLLetraD(){           
            this.ss.printLLetraD(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalLetraEJM(){
            this.$refs['modalLetraEJM'].show();
        },
        cancelLetraEJM() {
            this.$refs['modalLetraEJM'].hide();
        },
        printLLetraEJM(){            
            this.ss.printLLetraEJM(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalLetraEJO(){
            this.$refs['modalLetraEJO'].show();
        },
        cancelLetraEJO() {
            this.$refs['modalLetraEJO'].hide();
        },
        printLLetraEJO(){            
            this.ss.printLLetraEJO(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },     
        
        modalArt87(){
            this.$refs['modalArt87'].show();
        },
        cancelArt87() {
            this.$refs['modalArt87'].hide();
        },
        printLArt87(){            
            this.ss.printLArt87(this.variables)
                .then(  
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalArt114(){
            this.$refs['modalArt114'].show();
        },
        cancelArt114() {
            this.$refs['modalArt114'].hide();
        },
        printLArt114(){            
            this.ss.printLArt114(this.variables)
                .then(  
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalSinEfectoLetraEDispPJM(){
            this.$refs['modalSinEfectoLetraEDispPJM'].show();
        },
        cancelSinEfectoLetraEDispPJM() {
            this.$refs['modalSinEfectoLetraEDispPJM'].hide();
        },
        printLSinEfectoLetraEDispPJM(){            
            this.ss.printLSinEfectoLetraEDispPJM(this.variables)
                .then(  
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },                        
        
        modalConclusionLetraB(){
            this.$refs['modalConclusionLetraB'].show();
        },
        cancelConclusionLetraB() {
            this.$refs['modalConclusionLetraB'].hide();
        },
        printCConclusionLetraB(){            
            this.ss.printCConclusionLetraB(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalConclusionLetraC(){
            this.$refs['modalConclusionLetraC'].show();
        },
        cancelConclusionLetraC() {
            this.$refs['modalConclusionLetraC'].hide();
        },
        printCConclusionLetraC(){            
            this.ss.printCConclusionLetraC(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalConclusionLetraD(){
            this.$refs['modalConclusionLetraD'].show();
        },
        cancelConclusionLetraD() {
            this.$refs['modalConclusionLetraD'].hide();
        },
        printCConclusionLetraD(){            
            this.ss.printCConclusionLetraD(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalConclusionLetraEJM(){
            this.$refs['modalConclusionLetraEJM'].show();
        },
        cancelConclusionLetraEJM() {
            this.$refs['modalConclusionLetraEJM'].hide();
        },
        printCConclusionLetraEJM(){            
            this.ss.printCConclusionLetraEJM(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalConclusionLetraEJO(){
            this.$refs['modalConclusionLetraEJO'].show();
        },
        cancelConclusionLetraEJO() {
            this.$refs['modalConclusionLetraEJO'].hide();
        },
        printCConclusionLetraEJO(){            
            this.ss.printCConclusionLetraEJO(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalConclusionProLibro(){
            this.$refs['modalConclusionProLibro'].show();
        },
        cancelConclusionProLibro() {
            this.$refs['modalConclusionProLibro'].hide();
        },
        printCConclusionProLibro(){            
            this.ss.printCConclusionProLibro(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalConclusionCursoMil(){
            this.$refs['modalConclusionCursoMil'].show();
        },
        cancelConclusionCursoMil() {
            this.$refs['modalConclusionCursoMil'].hide();
        },
        printCConclusionCursoMil(){            
            this.ss.printCConclusionCursoMil(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalConclusionDestinoTemp(){
            this.$refs['modalConclusionDestinoTemp'].show();
        },
        cancelConclusionDestinoTemp() {
            this.$refs['modalConclusionDestinoTemp'].hide();
        },
        printCConclusionDestinoTemp(){            
            this.ss.printCConclusionDestinoTemp(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },

        modalExoneracion(){
            this.$refs['modalExoneracion'].show();
        },
        cancelExoneracion() {
            this.$refs['modalExoneracion'].hide();
        },
        printBExoneracion(){            
            this.ss.printBExoneracion(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },

        modalFallecido(){
            this.$refs['modalFallecido'].show();
        },
        cancelFallecido() {
            this.$refs['modalFallecido'].hide();
        },
        printBFallecido(){            
            this.ss.printBFallecido(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },

        modalJubilacion(){
            this.$refs['modalJubilacion'].show();
        },
        cancelJubilacion() {
            this.$refs['modalJubilacion'].hide();
        },
        printBJubilacion(){            
            this.ss.printBJubilacion(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },        

        modalRetiro89(){
            this.$refs['modalRetiro89'].show();
        },
        cancelRetiro89() {
            this.$refs['modalRetiro89'].hide();
        },
        printBRetiro89(){            
            this.ss.printBRetiro89(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },

        modalRetiro90(){
            this.$refs['modalRetiro90'].show();
        },
        cancelRetiro90() {
            this.$refs['modalRetiro90'].hide();
        },
        printBRetiro90(){            
            this.ss.printBRetiro90(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalBajaTLM(){
            this.$refs['modalBajaTLM'].show();
        },
        cancelBajaTLM() {
            this.$refs['modalBajaTLM'].hide();
        },
        printBBajaTLM(){            
            this.ss.printBBajaTLM(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalBajaLT(){
            this.$refs['modalBajaLT'].show();
        },
        cancelBajaLT() {
            this.$refs['modalBajaLT'].hide();
        },
        printBBajaLT(){            
            this.ss.printBBajaLT(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },

        modalOGA(){
            this.$refs['modalOGA'].show();
        },
        cancelOGA() {
            this.$refs['modalOGA'].hide();
        },
        printASOGA(){            
            this.ss.printASOGA(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalPosteriorOGA(){
            this.$refs['modalPosteriorOGA'].show();
        },
        cancelPosteriorOGA() {
            this.$refs['modalPosteriorOGA'].hide();
        },
        printASPosteriorOGA(){            
            this.ss.printASPosteriorOGA(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalPostumo(){
            this.$refs['modalPostumo'].show();
        },
        cancelPostumo() {
            this.$refs['modalPostumo'].hide();
        },
        printASPostumo(){            
            this.ss.printASPostumo(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalEscalafon(){
            this.$refs['modalEscalafon'].show();
        },
        cancelEscalafon() {
            this.$refs['modalEscalafon'].hide();
        },
        printVEscalafon(){            
            this.ss.printVEscalafon(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        
        modalModificacion(){
            this.$refs['modalModificacion'].show();
        },
        cancelModificacion() {
            this.$refs['modalModificacion'].hide();
        },
        printVModificacion(){            
            this.ss.printVModificacion(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },

        modalEdad(){
            this.$refs['modalEdad'].show();
        },
        cancelEdad() {
            this.$refs['modalEdad'].hide();
        },
        printVEdad(){            
            this.ss.printVEdad(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        
        modalOGD(){
            this.$refs['modalOGD'].show();
        },
        cancelOGD() {
            this.$refs['modalOGD'].hide();
        },
        printDOGD(){            
            this.ss.printDOGD(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalPosterior(){
            this.$refs['modalPosterior'].show();
        },
        cancelPosterior() {
            this.$refs['modalPosterior'].hide();
        },
        printDPosterior(){            
            this.ss.printDPosterior(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalRadicatoria(){
            this.$refs['modalRadicatoria'].show();
        },
        cancelRadicatoria() {
            this.$refs['modalRadicatoria'].hide();
        },
        printDRadicatoria(){            
            this.ss.printDRadicatoria(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalCurMil(){
            this.$refs['modalCurMil'].show();
        },
        cancelCurMil() {
            this.$refs['modalCurMil'].hide();
        },
        printDCurMil(){            
            this.ss.printDCurMil(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalTemporal(){
            this.$refs['modalTemporal'].show();
        },
        cancelTemporal() {
            this.$refs['modalTemporal'].hide();
        },
        printDTemporal(){            
            this.ss.printDTemporal(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalReservaAct(){
            this.$refs['modalReservaAct'].show();
        },
        cancelReservaAct() {
            this.$refs['modalReservaAct'].hide();
        },
        printDReservaAct(){            
            this.ss.printDReservaAct(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        modalPersPermServActEJTO(){
            this.$refs['modalPersPermServActEJTO'].show();
        },
        cancelPersPermServActEJTO() {
            this.$refs['modalPersPermServActEJTO'].hide();
        },
        printDPersPermServActEJTO(){            
            this.ss.printDPersPermServActEJTO(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },

        modalPosteriorAlfabetico(){
            this.$refs['modalPosteriorAlfabetico'].show();
        },
        cancelPosteriorAlfabetico() {
            this.$refs['modalPosteriorAlfabetico'].hide();
        },
        printDPosteriorAlfabetico(){            
            this.ss.printDPosteriorAlfabetico(this.variables)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },

        reportecuadro(){       
            console.log(this.cuadros.Daen, ' esto es cuadros daen'); 
            console.log(this.cuadros.Dem, ' esto es cuadros dem'); 
            if(this.cuadros.Daen == true){
                 this.cuadros.Daen = 2;
                 
            }
            else{
                this.cuadros.Daen = 0;

            }
            if(this.cuadros.Dem == true){
                this.cuadros.Dem = 3; 

           }
           else{
               this.cuadros.Dem = 0;

           }
           if(this.cuadros.Depss == true){
            this.cuadros.Depss = 5;
            
           }
           else{
                this.cuadros.Depss = 0;

            }
            if(this.cuadros.Dim == true){
                this.cuadros.Dim = 4;
                
           }
           else{
               this.cuadros.Dim = 0;

           }
           if(this.cuadros.Magister == true){
            this.cuadros.Magister = 1;
            
            }
            else{
                this.cuadros.Magister = 0;

            }
            if(this.cuadros.Frontera == true){
                this.cuadros.Frontera = 7;
                
            }
            else{
                this.cuadros.Frontera = 0;
    
            }
            if(this.cuadros.Profesional == true){
                this.cuadros.Profesional = 8;
                
            }
            else{
                this.cuadros.Profesional = 0;
    
            }
            if(this.cuadros.ProLibro == true){
                this.cuadros.ProLibro = 6;
                
            }
            else{
                this.cuadros.ProLibro = 0;
    
            }
            if(this.cuadros.incorpInstituosMil == true){
                this.cuadros.incorpInstituosMil = 9;
                
            }
            else{
                this.cuadros.incorpInstituosMil = 0;
    
            }
            if(this.cuadros.incorpPersCivil == true){
                this.cuadros.incorpPersCivil = 10;
                
            }
            else{
                this.cuadros.incorpPersCivil = 0;
    
            }
            if(this.cuadros.reincorpPersCivil == true){
                this.cuadros.reincorpPersCivil = 12;
                
            }
            else{
                this.cuadros.reincorpPersCivil = 0;
    
            }
            if(this.cuadros.reincorpPersMil == true){
                this.cuadros.reincorpPersMil = 11;
                
            }
            else{
                this.cuadros.reincorpPersMil = 0;
    
            }
            if(this.cuadros.incorpArt114 == true){
                this.cuadros.incorpArt114 = 51;
                
            }
            else{
                this.cuadros.incorpArt114 = 0;
    
            }
            if(this.cuadros.incorpArt118 == true){
                this.cuadros.incorpArt118 = 52;
                
            }
            else{
                this.cuadros.incorpArt118 = 0;
    
            }
            if(this.cuadros.Lactancia == true){
                this.cuadros.Lactancia = 59;
                
            }
            else{
                this.cuadros.Lactancia = 0;
    
            }
            if(this.cuadros.Natalidad == true){
                this.cuadros.Natalidad = 14;
                
            }
            else{
                this.cuadros.Natalidad = 0;
    
            }
            if(this.cuadros.Mortalidad == true){
                this.cuadros.Mortalidad = 15;
                
            }
            else{
                this.cuadros.Mortalidad = 0;
    
            }
            if(this.cuadros.Prenatal == true){
                this.cuadros.Prenatal = 13;
                
            }
            else{
                this.cuadros.Prenatal = 0;
    
            }
            if(this.cuadros.LetraA == true){
                this.cuadros.LetraA = 16;
                
            }
            else{
                this.cuadros.LetraA = 0;
    
            }
            if(this.cuadros.LetraB == true){
                this.cuadros.LetraB = 17;
                
            }
            else{
                this.cuadros.LetraB = 0;
    
            }
            if(this.cuadros.LetraC == true){
                this.cuadros.LetraC = 18;
                
            }
            else{
                this.cuadros.LetraC = 0;
    
            }
            if(this.cuadros.LetraD == true){
                this.cuadros.LetraD = 19;
                
            }
            else{
                this.cuadros.LetraD = 0;
    
            }
            if(this.cuadros.LetraEjustMil == true){
                this.cuadros.LetraEjustMil = 20;
                
            }
            else{
                this.cuadros.LetraEjustMil = 0;
    
            }
            if(this.cuadros.LetraEjustOrd == true){
                this.cuadros.LetraEjustOrd = 46;
                
            }
            else{
                this.cuadros.LetraEjustOrd = 0;
    
            }
            if(this.cuadros.AplicArt87 == true){
                this.cuadros.AplicArt87 = 54;
                
            }
            else{
                this.cuadros.AplicArt87 = 0;
    
            }            
            if(this.cuadros.AplicArt114 == true){
                this.cuadros.AplicArt114 = 60;
                
            }
            else{
                this.cuadros.AplicArt114 = 0;
    
            }
            if(this.cuadros.cLetraB == true){
                this.cuadros.cLetraB = 22;
                
            }
            else{
                this.cuadros.cLetraB = 0;
    
            }
            if(this.cuadros.cLetraC == true){
                this.cuadros.cLetraC = 23;
                
            }
            else{
                this.cuadros.cLetraC = 0;
    
            }
            if(this.cuadros.cLetraD == true){
                this.cuadros.cLetraD = 24;
                
            }
            else{
                this.cuadros.cLetraD = 0;
    
            }
            if(this.cuadros.cLetraEjustMil == true){
                this.cuadros.cLetraEjustMil = 25;
                
            }
            else{
                this.cuadros.cLetraEjustMil = 0;
    
            }
            if(this.cuadros.cLetraEjustOrd == true){
                this.cuadros.cLetraEjustOrd = 48;
                
            }
            else{
                this.cuadros.cLetraEjustOrd = 0;
    
            }
            if(this.cuadros.cBonoProLibro == true){
                this.cuadros.cBonoProLibro = 26;
                
            }
            else{
                this.cuadros.cBonoProLibro = 0;
    
            }
            if(this.cuadros.cCursoMil == true){
                this.cuadros.cCursoMil = 28;
                
            }
            else{
                this.cuadros.cCursoMil = 0;
    
            }
            if(this.cuadros.cDestinoMil == true){
                this.cuadros.cDestinoMil = 27;
                
            }
            else{
                this.cuadros.cDestinoMil = 0;
    
            }
            if(this.cuadros.ExoneracionCargo == true){
                this.cuadros.ExoneracionCargo = 30;
                
            }
            else{
                this.cuadros.ExoneracionCargo = 0;
    
            }
            if(this.cuadros.Fallecimiento == true){
                this.cuadros.Fallecimiento = 29;
                
            }
            else{
                this.cuadros.Fallecimiento = 0;
    
            }
            if(this.cuadros.Jubilacion == true){
                this.cuadros.Jubilacion = 35;
                
            }
            else{
                this.cuadros.Jubilacion = 0;
    
            }
            if(this.cuadros.RetiroObligatorio == true){
                this.cuadros.RetiroObligatorio = 31;
                
            }
            else{
                this.cuadros.RetiroObligatorio = 0;
    
            }
            if(this.cuadros.RetiroVoluntario == true){
                this.cuadros.RetiroVoluntario = 34;
                
            }
            else{
                this.cuadros.RetiroVoluntario = 0;
    
            }
            if(this.cuadros.BajaTemporalLicenciaMaxima == true){
                this.cuadros.BajaTemporalLicenciaMaxima = 57;
                
            }
            else{
                this.cuadros.BajaTemporalLicenciaMaxima = 0;
    
            }
            if(this.cuadros.BajaLicenciaTemporal == true){
                this.cuadros.BajaLicenciaTemporal = 58;
                
            }
            else{
                this.cuadros.BajaLicenciaTemporal = 0;
    
            }
            if(this.cuadros.oga == true){
                this.cuadros.oga = 36;
                
            }
            else{
                this.cuadros.oga = 0;
    
            }
            if(this.cuadros.PostOga == true){
                this.cuadros.PostOga = 37;
                
            }
            else{
                this.cuadros.PostOga = 0;
    
            }
            if(this.cuadros.Postumo == true){
                this.cuadros.Postumo = 53;
                
            }
            else{
                this.cuadros.Postumo = 0;
    
            }
            if(this.cuadros.CambioEscalafon == true){
                this.cuadros.CambioEscalafon = 41;
                
            }
            else{
                this.cuadros.CambioEscalafon = 0;
    
            }
            if(this.cuadros.ModificacionDatos == true){
                this.cuadros.ModificacionDatos = 42;
                
            }
            else{
                this.cuadros.ModificacionDatos = 0;
    
            }
            if(this.cuadros.ogd == true){
                this.cuadros.ogd = 43;
                
            }
            else{
                this.cuadros.ogd = 0;
    
            }
            if(this.cuadros.PostOgd == true){
                this.cuadros.PostOgd = 44;
                
            }
            else{
                this.cuadros.PostOgd = 0;
    
            }
            if(this.cuadros.Radicatoria == true){
                this.cuadros.Radicatoria = 45;
                
            }
            else{
                this.cuadros.Radicatoria = 0;
    
            }
            if(this.cuadros.CursoMil == true){
                this.cuadros.CursoMil = 55;
                
            }
            else{
                this.cuadros.CursoMil = 0;
    
            }
            if(this.cuadros.DestTemp == true){
                this.cuadros.DestTemp = 56;
                
            }
            else{
                this.cuadros.DestTemp = 0;
    
            }
            if(this.cuadros.ReservaAct == true){
                this.cuadros.ReservaAct = 47;
                
            }
            else{
                this.cuadros.ReservaAct = 0;
    
            }
                
            console.log(this.cuadros.Daen, "esta variable antes de irse a daen bac")
            console.log(this.cuadros.Dem, "esta variable antes de irse a dem  bac")
            this.cuadrosba.Daen = this.cuadros.Daen
            this.cuadrosba.Dem = this.cuadros.Dem
            this.cuadrosba.Depss = this.cuadros.Depss
            this.cuadrosba.Dim = this.cuadros.Dim
            this.cuadrosba.Magister = this.cuadros.Magister
            this.cuadrosba.Frontera = this.cuadros.Frontera
            this.cuadrosba.Profesional = this.cuadros.Profesional
            this.cuadrosba.ProLibro = this.cuadros.ProLibro
            this.cuadrosba.incorpInstituosMil = this.cuadros.incorpInstituosMil
            this.cuadrosba.incorpPersCivil = this.cuadros.incorpPersCivil
            this.cuadrosba.reincorpPersCivil = this.cuadros.reincorpPersCivil
            this.cuadrosba.reincorpPersMil = this.cuadros.reincorpPersMil
            this.cuadrosba.incorpArt114 = this.cuadros.incorpArt114
            this.cuadrosba.incorpArt118 = this.cuadros.incorpArt118
            this.cuadrosba.Lactancia = this.cuadros.Lactancia
            this.cuadrosba.Natalidad = this.cuadros.Natalidad
            this.cuadrosba.Mortalidad = this.cuadros.Mortalidad
            this.cuadrosba.Prenatal = this.cuadros.Prenatal
            this.cuadrosba.LactanciaConPaquete = this.cuadros.LactanciaConPaquete
            this.cuadrosba.PrenatalConPaquete = this.cuadros.PrenatalConPaquete
            this.cuadrosba.LetraA = this.cuadros.LetraA
            this.cuadrosba.LetraB = this.cuadros.LetraB
            this.cuadrosba.LetraC = this.cuadros.LetraC
            this.cuadrosba.LetraD = this.cuadros.LetraD
            this.cuadrosba.LetraEjustMil = this.cuadros.LetraEjustMil
            this.cuadrosba.LetraEjustOrd = this.cuadros.LetraEjustOrd
            this.cuadrosba.AplicArt87 = this.cuadros.AplicArt87
            this.cuadrosba.AplicArt114 = this.cuadros.AplicArt114
            this.cuadrosba.cLetraB = this.cuadros.cLetraB
            this.cuadrosba.cLetraC = this.cuadros.cLetraC
            this.cuadrosba.cLetraD = this.cuadros.cLetraD
            this.cuadrosba.cLetraEjustMil = this.cuadros.cLetraEjustMil
            this.cuadrosba.cLetraEjustOrd = this.cuadros.cLetraEjustOrd
            this.cuadrosba.cBonoProLibro = this.cuadros.cBonoProLibro
            this.cuadrosba.cCursoMil = this.cuadros.cCursoMil
            this.cuadrosba.cDestinoMil = this.cuadros.cDestinoMil  
            this.cuadrosba.cDestinoMil = this.cuadros.cDestinoMil  
            this.cuadrosba.ExoneracionCargo = this.cuadros.ExoneracionCargo  
            this.cuadrosba.Fallecimiento = this.cuadros.Fallecimiento  
            this.cuadrosba.Jubilacion = this.cuadros.Jubilacion  
            this.cuadrosba.RetiroObligatorio = this.cuadros.RetiroObligatorio  
            this.cuadrosba.RetiroVoluntario = this.cuadros.RetiroVoluntario  
            this.cuadrosba.BajaTemporalLicenciaMaxima = this.cuadros.BajaTemporalLicenciaMaxima  
            this.cuadrosba.BajaLicenciaTemporal = this.cuadros.BajaLicenciaTemporal  
            this.cuadrosba.oga = this.cuadros.oga  
            this.cuadrosba.PostOga = this.cuadros.PostOga  
            this.cuadrosba.Postumo = this.cuadros.Postumo  
            this.cuadrosba.CambioEscalafon = this.cuadros.CambioEscalafon  
            this.cuadrosba.ModificacionDatos = this.cuadros.ModificacionDatos  
            this.cuadrosba.ogd = this.cuadros.ogd  
            this.cuadrosba.PostOgd = this.cuadros.PostOgd  
            this.cuadrosba.Radicatoria = this.cuadros.Radicatoria  
            this.cuadrosba.CursoMil = this.cuadros.CursoMil  
            this.cuadrosba.DestTemp = this.cuadros.DestTemp  
            this.cuadrosba.ReservaAct = this.cuadros.ReservaAct 
            this.cuadrosba.PostOgdAlfabetico = this.cuadros.PostOgdAlfabetico 
            console.log(this.cuadrosba.Daen, "esta variable daen bac 111")
            console.log(this.cuadrosba.Dem, "esta variable  dem  bac 111")
            this.ss.printReporteCuadro(this.cuadrosba)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        
        
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        this.getConceptoSP();
        this.getSituacioConcepto();
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } 
    }

    
};
