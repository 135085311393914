import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PasajePage",
    data() {
        let ss = new MainService();
        return {
            msg: "PasajePage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexPasaje(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Pasaje', name: 'pa.Pasaje', title: 'Pasaje' },
                { data: 'TipoPasaje', name: 'tp.TipoPasaje', title: 'Tipo Pasaje' },
                { data: 'LugarOrigen', name: 'luo.Lugar', title: 'Lugar Origen' },
                { data: 'LugarDestino', name: 'lud.Lugar', title: 'Lugar Destino' },
                { data: 'MontoPasaje', name: 'pa.MontoPasaje', title: 'MontoPasaje' },
                { data: 'Distancia', name: 'pa.Distancia', title: 'Distancia' },
                { data: 'Dias', name: 'pa.Dias', title: 'Dias' },
                { data: 'Equipaje', name: 'pa.Equipaje', title: 'Equipaje' },
                { data: 'Fecha', name: 'pa.Fecha', title: 'Fecha' },
                { data: 'Activo', name: 'pa.Activo', title: 'Activo' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxTramo: {
                url: ss.indexTramo(),
                headers: ss.getToken(),
                data: function (d) {
                    d.Pasaje = 0;
                    }
              },
            columnsTramo: [
            { data: 'id', name: 'id', orderable: false, searchable: false, visible: false},
            {  data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false                },
            { data: 'Tramo', name: 'Tramo', title: 'Tramo' },
            { data: 'Descripcion', name: 'Descripcion', title: 'Descripcion' },
            { data: 'Monto', name: 'Monto', title: 'Monto' },
            { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
            { data: 'Origen', name: 'Origen', title: 'Origen' },
            { data: 'Destino', name: 'Destino', title: 'Destino' },
            { data: 'Distancia', name: 'Distancia', title: 'Distancia' },
            { data: 'Tiempo', name: 'Tiempo', title: 'Tiempo' },
            { data: 'Equipaje', name: 'Equipaje', title: 'Equipaje' },
            { data: 'TipoTramo', name: 'TipoTramo', title: 'TipoTramo' },
            ],
            rols: [],
            pasajes: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            pasaje: {},
            tramo: {},
            tipopasajes:[],
            lugar:[]            
        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.pasaje.Imagen = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        getTipoPasaje() {
            this.ss.listTipoPasaje().then(
                (result) => {
                    let response = result.data;
                    this.tipopasajes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getLugar() {
            this.ss.listLugar().then(
                (result) => {
                    let response = result.data;
                    this.lugar = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        newPasaje() {
            this.pasaje = {};
            this.$refs['frm-pasaje'].show();
            // console.log("hhhhola")
        },
        showPasaje(id) {
            this.isLoading=true;
            this.ss.showPasaje(id).then(
                (result) => {
                    let response = result.data;
                    this.pasaje = response.data;
                    this.ajaxTramo.data = function(d){
                        d.Pasaje = id;
                    }
                    this.$refs['view-pasaje'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPasaje() {
            this.$refs['frm-pasaje'].show();
            this.$refs['view-pasaje'].hide();
        },
        cancelPasaje() {
            if (this.pasaje.id) {
                this.$refs['view-pasaje'].show();
            }
            this.$refs['frm-pasaje'].hide();
        },
        savePasaje() {
            this.ss.storePasaje(this.pasaje).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-pasaje'].hide();
                    this.$refs['datatable-pasaje'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePasaje() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPasaje(this.pasaje)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-pasaje'].hide();
                                this.$refs['datatable-pasaje'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Pasaje').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPasaje(data);
            });
        },
        newTramo() {
            this.tramo = {};
            this.$refs['frm-tramo'].show();
        },
        saveTramo() {
            this.tramo.Pasaje = this.pasaje.id
            this.ss.storeTramo(this.tramo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-tramo'].hide();
                    // this.$refs['datatable-pasaje'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },


    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        
        this.getTipoPasaje();
        this.getLugar();
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } 
    }
};
