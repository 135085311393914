import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PersonaViaticoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaViaticoPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexPersonaViatico(),
                headers: ss.getToken(),
            },
            columns: [
                //{ data: 'id', name: 'id', orderable: false, searchable: false, visible: true },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'p.Persona', title: 'Persona Viático' },
                { data: 'FechaViatico', name: 'pv.FechaViatico', title: 'Fecha', searchable: false },
                { data: 'Reparticiono', name: 'r.Reparticion', title: 'RR. Origen' },
                { data: 'Reparticionn', name: 'rn.Reparticion', title: 'RR. Nueva' },
                { data: 'NumPersonas', name: 'pv.NumPersonas', title: 'NumPersonas' },
                { data: 'Pasaje', name: 'pv.Pasaje', title: 'Pasaje' },
                { data: 'CategoriaViatico', name: 'pv.CategoriaViatico', title: 'Viático' },
                { data: 'FleteAlmacenamiento', name: 'pv.FleteAlmacenamiento', title: 'F.A.' },
                { data: 'TotalViatico', name: 'pv.TotalViatico', title: 'Monto Total Bs. ' },
                { data: 'EstadoViatico', name: 'ev.EstadoViatico', title: 'Estado' },
                //{ data: 'idPersona', name: 'idPersona', title: 'idPersona', searchable: false },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rols: [],
            personaviaticos: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            personaviatico: {},
            personaviaticofamiliar: {},
            personas: [],
            reparticion: [],
            reparticiones: [],           
            estadoviatico: [],
            tipoviatico: [],
            pasaje: [],
            categoriaviatico: [],
            fletealmacenamiento: [],
            suma:0,
            MontoPasaje:0,
            MontoViatico:0,
            MontoTotalFA:0,
            MontoFactor:0,
            Equipaje:0,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            esposa:0,
            hijo:0,
            pasajes:0,
            pas:0,
            totalsinfamilia:0,
            totalsinpasaje:0,
            totalviatico:0,
            sumesp:0,
            sumpasaje:0,
            sumhijo:0,
            final:0,
            factor:0,
            personasSelect:[],
            placeholderPersona:"",
            placeholderReparticion:"",
            placeholderPasaje:"",
            selectedPersona: null,
            searchPersona:'',
            searchReparticion:'',
            searchPasaje:'',
            hasPerson:false
        };
    },
    methods: {
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },  


        getFactorEquipaje(){
            console.log("Esto es una mierda"); 
            if(this.personaviatico.Pasaje){              
                let equi = this.pasaje.filter(e => {                   
                    return e.id == this.personaviatico.Pasaje;                
                })[0];               
                    console.log("factor equipaje", equi);               
                    this.personaviatico.Equipaje = equi.Equipaje;    
                    this.getMontoPasaje();

                    }
        },
        getMontoPasaje()
        {
            // this.personaviatico.Equipaje=eq;
            let MPasaje = {};            
            if(this.personaviatico.Pasaje){              
                MPasaje = this.pasaje.filter(e => {                   
                    return e.id == this.personaviatico.Pasaje;                
                })[0];               
                    console.log("monto pasaje", MPasaje);               
                    this.MontoPasaje = MPasaje.MontoPasaje;    
                    if(this.pasajes=="1"){
                        this.MontoPasaje=this.MontoPasaje*1;
                    }
                    if(this.pasajes=="2"){
                        console.log("if");
                        this.MontoPasaje=this.MontoPasaje*2;
                    }
                    if(this.pasajes=="3"){
                        console.log("if");
                        this.MontoPasaje=this.MontoPasaje*3;
                        
                    } 
                    if(this.pasajes=="4"){
                        console.log("if");
                        this.MontoPasaje=this.MontoPasaje*4;
                        
                    } 
                    if(this.pasajes=="5"){
                        console.log("if");
                        this.MontoPasaje=this.MontoPasaje*5;
                        
                    } 
                    if(this.pasajes=="6"){
                        console.log("if");
                        this.MontoPasaje=this.MontoPasaje*6;
                        
                    } 
                    if(this.pasajes=="7"){
                        console.log("if");
                        this.MontoPasaje=this.MontoPasaje*7;
                        
                    } 
                    if(this.pasajes=="8"){
                        console.log("if");
                        this.MontoPasaje=this.MontoPasaje*8;
                        
                    } 
                    if(this.pasajes=="9"){
                        console.log("if");
                        this.MontoPasaje=this.MontoPasaje*9;
                        
                    }  
                    if(this.pasajes=="10"){
                        console.log("if");
                        this.MontoPasaje=this.MontoPasaje*10;
                        
                    }   
                   
                    }
            this.sumaMonto();
        },

        getMontoCV()
        {
            let Mcv = {};            
            if(this.personaviatico.CategoriaViatico){              
                Mcv = this.categoriaviatico.filter(e => {                   
                    return e.id == this.personaviatico.CategoriaViatico;                
                })[0];               
                    console.log("monto pasaje", Mcv);               
                    this.MontoViatico = Mcv.MontoViatico;    

                    }

            this.sumaMonto();
        },

        getMontoFA()
        {
            let Mfa = {};            
            if(this.personaviatico.FleteAlmacenamiento){              
                Mfa = this.fletealmacenamiento.filter(e => {                   
                    return e.id == this.personaviatico.FleteAlmacenamiento;                
                })[0];               
                    console.log("monto fletealmacenamiento", Mfa);               
                    console.log("monto pasaje", this.personaviatico.Equipaje);               
                    this.MontoTotalFA = Mfa.Factor * this.personaviatico.Equipaje;           
                    }

            this.sumaMonto();
            
        },

        sumapasaje(){
            console.log("pasajes");
            this.totalsinpasaje = this.MontoPasaje;
            if(this.pasajes=="1"){
                this.sumpasaje=this.totalsinpasaje*1;
                console.log(this.sumpasaje);
            }
            if(this.pasajes=="2"){
                console.log("if");
                this.sumpasaje=this.totalsinpasaje*2;
                console.log(this.sumpasaje);
            } 
            if(this.pasajes=="3"){
                console.log("if");
                this.sumpasaje=this.totalsinpasaje*3;
                console.log(this.sumpasaje);
            } 
            if(this.pasajes=="4"){
                console.log("if");
                this.sumpasaje=this.totalsinpasaje*4;
                console.log(this.sumpasaje);
            } 
            if(this.pasajes=="5"){
                console.log("if");
                this.sumpasaje=this.totalsinpasaje*5;
                console.log(this.sumpasaje);
            } 
            if(this.pasajes=="6"){
                console.log("if");
                this.sumpasaje=this.totalsinpasaje*6;
                console.log(this.sumpasaje);
            } 
            if(this.pasajes=="7"){
                console.log("if");
                this.sumpasaje=this.totalsinpasaje*7;
                console.log(this.sumpasaje);
            } 
            if(this.pasajes=="8"){
                console.log("if");
                this.sumpasaje=this.totalsinpasaje*8;
                console.log(this.sumpasaje);
            } 
            if(this.pasajes=="9"){
                console.log("if");
                this.sumpasaje=this.totalsinpasaje*9;
                console.log(this.sumpasaje);
            }             
        },

        sumaviaticoFam(){
            console.log("esposa");
            this.totalsinfamilia = this.MontoViatico;
            if(this.esposa=="1"){
                console.log("if");
                this.sumesp=this.totalsinfamilia*0.5;
                console.log(this.sumesp);
            }
            if(this.esposa=="0"){
                this.sumesp=0;
            }
        },

        sumaviaticohijo(){
            console.log("hijo");
            this.totalsinhijo = this.MontoViatico;
            if(this.hijo=="1"){
                console.log("if");
                this.sumhijo=this.totalsinhijo*0.25;
                console.log(this.sumhijo);
            }
            if(this.hijo=="2"){
                console.log("if");
                this.sumhijo=this.totalsinhijo*0.5;
                console.log(this.sumhijo);
            }
            if(this.hijo=="3"){
                console.log("if");
                this.sumhijo=this.totalsinhijo*0.75;
                console.log(this.sumhijo);
            }
            if(this.hijo=="4"){
                console.log("if");
                this.sumhijo=this.totalsinhijo*1;
                console.log(this.sumhijo);
            }
            if(this.hijo=="5"){
                console.log("if");
                this.sumhijo=this.totalsinhijo*1.25;
                console.log(this.sumhijo);
            }
            if(this.hijo=="6"){
                console.log("if");
                this.sumhijo=this.totalsinhijo*1.5;
                console.log(this.sumhijo);
            }
            if(this.hijo=="7"){
                console.log("if");
                this.sumhijo=this.totalsinhijo*1.75;
                console.log(this.sumhijo);
            }
            if(this.hijo=="8"){
                console.log("if");
                this.sumhijo=this.totalsinhijo*2
                console.log(this.sumhijo);
            }
            
        },

        sumafactor(){
            this.sumfactor=parseFloat(this.pasaje.Equipaje) * parseFloat(this.fletealmacenamiento.Factor);
        },

        sumaMonto(){
            this.suma=parseFloat(this.MontoViatico)+parseFloat(this.MontoTotalFA)+parseFloat(this.MontoPasaje);
            //this.personaviatico.TotalViatico = this.suma;
        },
        
        calculoViatico(){
            this.totalviatico=this.sumhijo+this.sumesp+this.MontoViatico;
        },

        calcularFinal(){
            this.final=this.suma+this.sumhijo+this.sumesp;
            this.personaviatico.TotalViatico = this.final;

        },

        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticion = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getEstadoViatico() {
            this.ss.listEstadoViatico().then(
                (result) => {
                    let response = result.data;
                    this.estadoviatico = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getTipoViatico() {
            this.ss.listTipoViatico().then(
                (result) => {
                    let response = result.data;
                    this.tipoviatico = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getPasaje() {
            this.ss.listPasaje().then(
                (result) => {
                    let response = result.data;
                    this.pasaje = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getCategoriaViatico() {
            this.ss.listCategoriaViatico().then(
                (result) => {
                    let response = result.data;
                    this.categoriaviatico = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getFleteAlmacenamiento() {
            this.ss.listFleteAlmacenamiento().then(
                (result) => {
                    let response = result.data;
                    this.fletealmacenamiento = response.data;
                }
            ).catch(error => {      
                console.log(error);
            });
        },
        // getReparticionOrigen() {
        //     this.ss.listReparticionOrigen().then(
        //         (result) => {
        //             let response = result.data;
        //             this.ReparticionOrigenes = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },

        newPersonaViatico() {
            this.personaviatico = {};
            this.$refs['frm-personaviatico'].show();
            // console.log("hhhhola")
        },

        cleanPerson() {
            this.hasPerson = false;
        },

        showPersonaViatico(id) {
            this.personaviatico={};
            console.log("id persona viatico",id);
            this.personaviaticofamiliar=[],
            this.isLoading=true;
            this.hasPerson = false;
            this.ss.showPersonaViatico(id).then(
                (result) => {
                    let response = result.data;
                    this.personaviatico = response.data;
                    if(this.personaviatico.persona){
                        this.hasPerson = true;
                    }
                    this.$refs['view-personaviatico'].show();
                    this.isLoading=false;
                        this.ss.showPersonaPorCodigo(this.personaviatico.Persona).then(
                        (result2) =>{
                            let response2 = result2.data;
                            console.log(response2);
                            this.actualizarPersonaFamiliar(response2);
                        }
                        )
                  //  this.actualizarPersonaFamiliar(this.personaviatico.Persona);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },


        actualizarPersonaFamiliar(item) {
            this.personaviaticofamiliar = [];
            this.isLoading=true;
            this.ss.listFamiliarlista(item.data).then(
                (result) => {
                    let response = result.data;
                    this.personaviaticofamiliar = response.data;
                    console.log("tam",this.personaviaticofamiliar.length);

                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        showPersonaFamiliar(item) {
            this.personaviaticofamiliar = [];
            console.log("tilinnnn");
            this.isLoading=true;
            this.ss.listFamiliarlista(item).then(
                (result) => {
                    console.log(result)
                    let response = result.data;
                    this.personaviaticofamiliar = response.data;
                    this.$refs['frm-personaviatico'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPersonaViatico() {
            this.$refs['frm-personaviatico'].show();
            this.$refs['view-personaviatico'].hide();
        },
        cancelPersonaViatico() {
            if (this.personaviatico.id) {
                this.$refs['view-personaviatico'].show();
            }
            this.$refs['frm-personaviatico'].hide();
            
        },
        savePersonaViatico() {
            this.personaviatico.Pasaje = this.MontoPasaje
            this.personaviatico.CategoriaViatico = this.sumhijo + this.sumesp + this.MontoViatico
            this.personaviatico.FleteAlmacenamiento = this.MontoTotalFA
            this.personaviatico.TotalViatico = this.final
            this.personaviatico.Hijos = this.hijo
            this.personaviatico.Esposa = this.esposa
            this.ss.storePersonaViatico(this.personaviatico).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-personaviatico'].hide();
                    this.$refs['datatable-personaviatico'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePersonaViatico() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPersonaViatico(this.personaviatico)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-personaviatico'].hide();
                                this.$refs['datatable-personaviatico'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
         
        printPersonaViatico() {
            console.log(this.personaviatico.id);
            this.ss.imprimirPersonaViatico(this.personaviatico.id).then(
                (result) => {
                    let response = result.data;
                    window.open(this.rutaApp+response.data.url);
                    console.log(response, "Se imprimio Reporte");
                }
            ).catch(error => {
                console.log(error);
            });
        },

        

        draw() {
            window.$('.btn-datatable-PersonaViatico').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersonaViatico(data);
            });
        }
    },

    watch: {
        searchPersona(q) {
            this.ss.select22Persona(q).then((res) => {
                if (res.data.success) {
                   
                    this.personas = res.data.items;
                                   
                } else {
                    this.personas = [];
                }
            })
        },
        selectedPersona(persona){
            console.log(persona)
            this.personaviatico.Persona = persona.CodigoPersona;
            this.showPersonaFamiliar(persona)
        },

        // searchReparticionOrigen(q) {
        //     this.ss.select2ReparticionOrigen(q).then((res) => {
        //         if (res.data.success) {
                   
        //             this.ReparticionOrigenes = res.data.items;
                                   
        //         } else {
        //             this.ReparticionOrigenes = [];
        //         }
        //     })
        // },
        searchReparticion(q) {
            this.ss.select2Reparticion(q).then((res) => {
                if (res.data.success) {
                   
                    this.reparticiones = res.data.items;
                                   
                } else {
                    this.reparticiones = [];
                }
            })
        },

        searchPasaje(q) {
            this.ss.select2Pasaje(q).then((res) => {
                if (res.data.success) {
                   
                    this.pasajes = res.data.items;
                                   
                } else {
                    this.pasajes = [];
                }
            })
        },

    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        // this.getPersona();
        this.getReparticion();
        this.getEstadoViatico();
        this.getTipoViatico();
        this.getPasaje();
        this.getCategoriaViatico();
        this.getFleteAlmacenamiento();
        // this.getReparticionOrigen();  //***//
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
            
        } 
    }
};
