var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{ enabled: true, placeholder: 'Search this table' },"pagination-options":{ enabled: true,  position: 'bottom' },"selectOptions":{
			enabled: true,
			selectOnCheckboxOnly: true,
			selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
			selectionText: 'rows selected',
			clearSelectionText: 'clear',
		}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'image')?_c('span',[_c('img',{staticClass:"rounded",staticStyle:{"margin":"-3px"},attrs:{"src":props.row.image,"width":"24"}})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticStyle:{"margin":"-2px 0"},attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',{staticClass:"btn btn-xs btn-primary m-r-5"},[_vm._v("Action 1")]),_c('button',{staticClass:"btn btn-xs btn-grey"},[_vm._v("Action 2")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"breadcrumb pull-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Home")])]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Tables")])]),_c('li',{staticClass:"breadcrumb-item active"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Data Tables")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"page-header"},[_vm._v("Vue Data Tables "),_c('small',[_vm._v("official documentation "),_c('a',{attrs:{"href":"https://xaksis.github.io/vue-good-table/","target":"_blank"}},[_vm._v("here")])])])
}]

export { render, staticRenderFns }