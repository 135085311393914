import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "DestinoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "DestinoPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexDestino(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Destino', name: 'd.Destino', title: 'Destino' },
                { data: 'Grado', name: 'g.Grado', title: 'Grado' },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Reparticion' },
                { data: 'Cargo', name: 'c.Cargo', title: 'Cargo' },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            destinos: [],
            grados: [],
            reparticiones: [],
            cargos: [],
            personas: [],
            queryPersona: '',
            placeholderPersona: '',
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            destino: {},

        };
    },
    methods: {

        getGrado() {
            this.ss.listGrado().then(
                (result) => {
                    let response = result.data;
                    this.grados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getCargo() {
            this.ss.listCargo().then(
                (result) => {
                    let response = result.data;
                    this.cargos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.destino.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        newDestino() {
            this.destino = {};
            this.$refs['frm-destino'].show();
            this.placeholderPersona = 'Escriba el nombre de la Persona';
            // console.log("hhhhola")
        },
        showDestino(id) {
            this.isLoading = true;
            this.ss.showDestino(id).then(
                (result) => {
                    let response = result.data;
                    this.destino = response.data;
                    this.$refs['view-destino'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editDestino() {
            this.$refs['frm-destino'].show();
            this.$refs['view-destino'].hide();
            if (this.destino.persona) {

                this.placeholderPersona = this.destino.persona.Persona;
            } else {

                this.placeholderPersona = 'Escriba el nombre de la Persona';
            }
        },
        cancelDestino() {
            if (this.destino.id) {
                this.$refs['view-destino'].show();
            }
            this.$refs['frm-destino'].hide();
        },
        saveDestino() {
            this.ss.storeDestino(this.destino).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-destino'].hide();
                    this.$refs['datatable-destino'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteDestino() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyDestino(this.destino)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-destino'].hide();
                                this.$refs['datatable-destino'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-destino'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-destino'].show();
        },
        changePassword() {
            this.password.Destino = this.destino.id;
            this.ss.changePassword(this.password)
                .then(result => {
                    let response = result.data;
                    if (response.success) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-destino'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        draw() {
            window.$('.btn-datatable-Destino').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showDestino(data);
            });
        }
    },
    watch: {
        queryPersona(q) {
            this.ss.select2Persona(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.personas = res.data.items;
                } else {
                    this.personas = [];
                }
            })
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {

        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getGrado();
            this.getReparticion();
            this.getCargo();
        }
    }
};
