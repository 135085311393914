import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PersonaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },        
                { data: 'Grado', name: 'g.Grado', title: 'Grado' },
                { data: 'Arma', name: 'a.Arma', title: 'Arma' },
                { data: 'Especialidad', name: 'e.Especialidad', title: 'Especialidad' },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'FechaNacimiento', name: 'p.FechaNacimiento', title: 'Fecha de Nacimiento' },
                { data: 'CI', name: 'p.CI', title: 'Cedula de Identidad' },
                { data: 'CodMinDef', name: 'p.CodMinDef', title: 'CodMinDef' },
                { data: 'email', name: 'p.email', title: 'Correo Electronico' },                
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Reparticion' },                
                { data: 'CodigoPersona', name: 'p.CodigoPersona', title: 'Codigo Persona' },
                { data: 'Celular', name: 'p.Celular', title: 'Celular' },
                // { data: 'Rol', name: 'Rol', title: 'Rol' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxFamiliar: {
                url: ss.indexFamiliarPorPersona(),
                headers: ss.getToken(),
                data: function (d) {
                    d.Persona = 0;
                    }
              },
            columnsFamiliar: [
            { data: 'id', name: 'id', orderable: false, searchable: false, visible: false},
            {  data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false                },
            { data: 'TipoFamiliar', name: 'tf.TipoFamiliar', title: 'Tipo de Familiar' },
            { data: 'Profesion', name: 'po.Profesion', title: 'Profesion' },
            { data: 'Familiar', name: 'fa.Familiar', title: 'Familiar' },
            { data: 'CI', name: 'fa.CI', title: 'CI' },
            { data: 'Dpto', name: 'fa.Dpto', title: 'Dpto.' },
            { data: 'FechaNacimiento', name: 'fa.FechaNacimiento', title: 'Fecha de Nacimiento' },
            { data: 'Edad', name: 'fa.Edad', title: 'Edad' },
            { data: 'Verificado', name: 'fa.Verificado', title: 'Verificado',searchable: false, width: '10%',
                className: 'centradito',
                render: function (data, type, row) {
                if (row.Verificado == false) {
                    return '<i class="fa fa-ban text-danger"> No Verificado</i>';
                } else {
                    return '<i class="fa fa-check text-success"> Verificado</i>';
                }
                },
            },
            {
                data: 'action',
                orderable: false,
                title: 'Acciones',
                searchable: false,
            },
            ],
            // ajaxDestino: {
            //     url: ss.indexDestinoPorPersona(),
            //     headers: ss.getToken(),
            //     data: function (d) {
            //         d.Persona = null;
            //         }
            //   },
            // columnsDestino: [
            // { data: 'id', name: 'id', orderable: false, searchable: false, visible: false},
            // { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false                },
            // { data: 'TipoDocumento', name: 'd.TipoDocumento', title: 'Tipo Documento' },
            // { data: 'Reparticion', name: 'ra.Reparticion', title: 'Repartición Anterior' },
            // { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición' },
            // { data: 'Reparticion', name: 'rm.Reparticion', title: 'Repartición MinDef.' },
            // { data: 'Reparticion', name: 'rt.Reparticion', title: 'Repartición Temporal' },
            // ],
            rols: [],
            personas: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            persona: {},
            carga: {},
            familiar: {},
            familiares: [],
            sexos: [],
            estadociviles: [],
            gruposanguineos: [],
            profesion: {},
            profesiones: [],
            tipoFamiliares: [],
            fuerzas: [],
            armas: [],
            departamentos: [],
            grados: [],
            reparticiones: [],
            cargos: [],
            especialidades: [],
            destinos: [],
            destino: {},

            personasSelect:[],
            placeholderPersona:"",
            placeholderGrado:"",
            placeholderCargo:"",
            placeholderReparticion:"",
            placeholderEspecialidad:"",
            placeholderArma:"",
            selectedPersona: null,
            searchPersona:'',
            searchGrado:'',
            searchArma:'',
            searchReparticion:'',
            searchEspecialidad:'',
            searchCargo:'',
            hasPerson:false,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            
            
        };
    },
    methods: {
        calcularEdad() {
            if (!this.familiar.FechaNacimiento) return;
      
            const fechaNacimiento = new Date(this.familiar.FechaNacimiento);
            const hoy = new Date();
            let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
            const mes = hoy.getMonth() - fechaNacimiento.getMonth();
      
            if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
              edad--;
            }
      
            this.familiar.Edad = edad;
          },
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        
        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getProfesion() {
            this.ss
              .listProfesion()
              .then((result) => {
                let response = result.data;
                this.profesiones = response.data;
              })
              .catch((error) => {
                console.log(error);
              });
          },
          getTipoFamiliar() {
            this.ss.listTipoFamiliar().then(
                (result) => {
                    let response = result.data;
                    this.tipoFamiliares = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPersona() {
            this.persona = {};
            this.$refs['frm-persona'].show();
            // console.log("hhhhola")
        },
        newPersonaFamiliar() {
            this.familiar = {};
            this.$refs['frm-familiar'].show();
          },
        showPersona(id) {
            this.isLoading=true;
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.persona = response.data;

                    var dd =  window.$('#datatable-familiar').DataTable();
                    console.log("filas", dd.rows());
                    this.ajaxFamiliar.data = function(d){
                        d.Persona = id;
                    }
                    // var de =  window.$('#datatable-destino').DataTable();
                    // console.log("filas", de.rows());
                    // this.ajaxDestino.data = function(d){
                    //     d.Persona = id;
                    // }

                    this.$refs['view-persona'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        showFamiliar(id) {
            this.isLoading = true;
            this.ss
              .showFamiliar(id)
              .then((result) => {
                let response = result.data;
                this.familiar = response.data;
                this.$refs['view-Familiar'].show();
                this.isLoading = false;
              })
              .catch((error) => {
                console.log(error);
                this.isLoading = false;
              });
          },
        editPersona() {
            this.$refs['frm-persona'].show();
            this.$refs['view-persona'].hide();
        },
        cancelPersona() {
            if (this.persona.id) {
                this.$refs['view-persona'].show();
            }
            this.$refs['frm-persona'].hide();
        },
        editFamiliar() {
            this.$refs['frm-familiar'].show();
            this.$refs['view-Familiar'].hide();
        },
        cancelFamiliar() {
            if (this.familiar.id) {
                this.$refs['view-familiar'].show();
            }
            this.$refs['frm-familiar'].hide();
        },
        savePersona() {
            this.ss.storePersona(this.persona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-persona'].hide();
                    this.$refs['datatable-persona'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        saveFamiliar() {
            console.log(this.persona.CodigoPersona)
            this.familiar.Persona = this.persona.CodigoPersona;
            this.ss
              .storeFamiliar(this.familiar)
              .then((result) => {
                console.log(result);
                let response = result.data;
                this.$bvToast.toast(response.msg, {
                  title: 'Correcto',
                  variant: 'success',
                  autoHideDelay: 5000,
                });
                this.$refs['frm-familiar'].hide();
                this.$refs['view-persona'].hide();
                this.$refs['view-Familiar'].hide();
                this.$refs['datatable-familiar'].reload();
                this.showPersona(this.persona.id);
              })
              .catch((error) => {
                //   this.errorBag = error.response.data.errors;
                console.log(error);
                this.$bvToast.toast(
                  'Problema al Guardar el Registro, favor verificar los Datos',
                  {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                  }
                );
              });
          },
        deletePersona() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPersona(this.persona)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-persona'].hide();
                                this.$refs['datatable-persona'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        deleteFamiliar() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyFamiliar(this.familiar)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-familiar'].hide();
                                this.$refs['datatable-familiar'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-persona'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-persona'].show();
        },
        changePassword() {
            this.password.Persona = this.persona.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-persona'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },

        getSexo() {
            this.ss.listSexo().then(
                (result) => {
                    let response = result.data;
                    this.sexos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getEstadoCivil() {
            this.ss.listEstadoCivil().then(
                (result) => {
                    let response = result.data;
                    this.estadociviles = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getGrupoSanguineo() {
            this.ss.listGrupoSanguineo().then(
                (result) => {
                    let response = result.data;
                    this.gruposanguineos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getFuerza() {
            this.ss.listFuerza().then(
                (result) => {
                    let response = result.data;
                    this.fuerzas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getArma() {
            this.ss.listArma().then(
                (result) => {
                    let response = result.data;
                    this.armas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getDepartamento() {
            this.ss.listDepartamento().then(
                (result) => {
                    let response = result.data;
                    this.departamentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        // getGrado() {
        //     this.ss.listGrado().then(
        //         (result) => {
        //             let response = result.data;
        //             this.grados = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },

        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getCargo() {
            this.ss.listCargo().then(
                (result) => {
                    let response = result.data;
                    this.cargos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getEspecialidad() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.especialidades = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getDestino() {
            this.destino.id = this.persona.id
            console.log('esto id mand a destino');
            this.ss.listDestinoIndividual(this.destino).then(
                (result) => {
                    let response = result.data;
                    this.destinos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        draw() {
            window.$('.btn-datatable-Persona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersona(data);
            });
        },
        drawFamiliar() {
            window.$('.btn-datatable-Familiar').on('click', (evt) => {
              const data = window.$(evt.target)[0].id;
              this.showFamiliar(data);
            });
          },
          printFiliacion(){  
            console.log('estoy mandando esto', this.persona) 
            this.carga.CodigoPersona = this.persona.CodigoPersona         
            this.carga.id = this.persona.id         
            console.log('estoy aqui', this.carga)
            this.ss.printPFiliacion(this.carga.CodigoPersona)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
    },
    watch: {
        searchPersona(q) {
            this.ss.select22Persona(q).then((res) => {
                if (res.data.success) {
                   
                    this.personas = res.data.items;
                                   
                } else {
                    this.personas = [];
                }
            })
        },
        selectedPersona(persona){
            console.log(persona)
            this.personaviatico.Persona = persona.CodigoPersona;
            this.showPersonaFamiliar(persona)
        },

        searchGrado(q) {
            this.ss.select2Grado(q).then((res) => {
                if (res.data.success) {
                   
                    this.grados = res.data.items;
                                   
                } else {
                    this.grados = [];
                }
            })
        },
        searchArma(q) {
            this.ss.select2Arma(q).then((res) => {
                if (res.data.success) {
                   
                    this.armas = res.data.items;
                                   
                } else {
                    this.armas = [];
                }
            })
        },
        searchReparticion(q) {
            this.ss.select2Reparticion(q).then((res) => {
                if (res.data.success) {
                   
                    this.reparticiones = res.data.items;
                                   
                } else {
                    this.reparticiones = [];
                }
            })
        },
        searchEspecialidad(q) {
            this.ss.select2Especialidad(q).then((res) => {
                if (res.data.success) {
                   
                    this.especialidades = res.data.items;
                                   
                } else {
                    this.especialidades = [];
                }
            })
        },
        searchCargo(q) {
            this.ss.select2Cargo(q).then((res) => {
                if (res.data.success) {
                   
                    this.cargos = res.data.items;
                                   
                } else {
                    this.cargos = [];
                }
            })
        },

        

    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            //this.getRol();
            this.getSexo();
            this.getProfesion();
            this.getEstadoCivil();
            this.getTipoFamiliar();
            this.getGrupoSanguineo();
            this.getFuerza();
            this.getArma();
            this.getDepartamento();
            //this.getGrado();
            this.getReparticion();
            this.getCargo();
            this.getEspecialidad();
            this.getDestino();
        }
    }
};
