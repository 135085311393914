var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"login-cover"},[_c('div',{staticClass:"login-cover-image",style:({ backgroundImage: 'url('+ _vm.bg.activeImg +')' })}),_c('div',{staticClass:"login-cover-bg"})]),_c('div',{staticClass:"login login-v2",attrs:{"data-pageload-addclass":"animated fadeIn"}},[_vm._m(0),_c('div',{staticClass:"login-content"},[_c('form',{staticClass:"margin-bottom-0",attrs:{"method":"POST"},on:{"submit":_vm.checkForm}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])]),_c('ul',{staticClass:"login-bg-list clearfix"},[_c('li',{class:{ 'active': _vm.bg.bg1.active }},[_c('a',{staticStyle:{"background-image":"url(/assets/img/login-bg/login-bg-17.jpg)"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.select('bg1')}}})]),_c('li',{class:{ 'active': _vm.bg.bg2.active }},[_c('a',{staticStyle:{"background-image":"url(/assets/img/login-bg/login-bg-16.jpg)"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.select('bg2')}}})]),_c('li',{class:{ 'active': _vm.bg.bg3.active }},[_c('a',{staticStyle:{"background-image":"url(/assets/img/login-bg/login-bg-15.jpg)"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.select('bg3')}}})]),_c('li',{class:{ 'active': _vm.bg.bg4.active }},[_c('a',{staticStyle:{"background-image":"url(/assets/img/login-bg/login-bg-14.jpg)"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.select('bg4')}}})]),_c('li',{class:{ 'active': _vm.bg.bg5.active }},[_c('a',{staticStyle:{"background-image":"url(/assets/img/login-bg/login-bg-13.jpg)"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.select('bg5')}}})]),_c('li',{class:{ 'active': _vm.bg.bg6.active }},[_c('a',{staticStyle:{"background-image":"url(/assets/img/login-bg/login-bg-12.jpg)"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.select('bg6')}}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-header"},[_c('div',{staticClass:"brand"},[_c('span',{staticClass:"logo"}),_vm._v(" "),_c('b',[_vm._v("Color")]),_vm._v(" Admin "),_c('small',[_vm._v("responsive bootstrap 4 admin template")])]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-lock"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group m-b-20"},[_c('input',{staticClass:"form-control form-control-lg",attrs:{"type":"text","placeholder":"Email Address","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group m-b-20"},[_c('input',{staticClass:"form-control form-control-lg",attrs:{"type":"password","placeholder":"Password","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox checkbox-css m-b-20"},[_c('input',{attrs:{"type":"checkbox","id":"remember_checkbox"}}),_c('label',{attrs:{"for":"remember_checkbox"}},[_vm._v(" Remember Me ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-buttons"},[_c('button',{staticClass:"btn btn-success btn-block btn-lg",attrs:{"type":"submit"}},[_vm._v("Sign me in")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-t-20"},[_vm._v(" Not a member yet? Click "),_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("here")]),_vm._v(" to register. ")])
}]

export { render, staticRenderFns }