var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login login-with-news-feed"},[_vm._m(0),_c('div',{staticClass:"right-content"},[_vm._m(1),_c('div',{staticClass:"login-content"},[_c('form',{staticClass:"margin-bottom-0",attrs:{"method":"POST"},on:{"submit":_vm.checkForm}},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"m-t-20 m-b-40 p-b-40 text-inverse"},[_vm._v(" Not a member yet? Click "),_c('router-link',{staticClass:"text-success",attrs:{"to":"/user/register-v3"}},[_vm._v("here")]),_vm._v(" to register. ")],1),_c('hr'),_c('p',{staticClass:"text-center text-grey-darker"},[_vm._v(" © Color Admin All Right Reserved 2020 ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-feed"},[_c('div',{staticClass:"news-image",staticStyle:{"background-image":"url(/assets/img/login-bg/login-bg-11.jpg)"}}),_c('div',{staticClass:"news-caption"},[_c('h4',{staticClass:"caption-title"},[_c('b',[_vm._v("Color")]),_vm._v(" Admin App")]),_c('p',[_vm._v(" Download the Color Admin app for iPhone®, iPad®, and Android™. Lorem ipsum dolor sit amet, consectetur adipiscing elit. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-header"},[_c('div',{staticClass:"brand"},[_c('span',{staticClass:"logo"}),_vm._v(" "),_c('b',[_vm._v("Color")]),_vm._v(" Admin "),_c('small',[_vm._v("responsive bootstrap 4 admin template")])]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-sign-in"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group m-b-15"},[_c('input',{staticClass:"form-control form-control-lg",attrs:{"type":"text","placeholder":"Email Address","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group m-b-15"},[_c('input',{staticClass:"form-control form-control-lg",attrs:{"type":"password","placeholder":"Password","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox checkbox-css m-b-30"},[_c('input',{attrs:{"type":"checkbox","id":"remember_me_checkbox","value":""}}),_c('label',{attrs:{"for":"remember_me_checkbox"}},[_vm._v(" Remember Me ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-buttons"},[_c('button',{staticClass:"btn btn-success btn-block btn-lg",attrs:{"type":"submit"}},[_vm._v("Sign me in")])])
}]

export { render, staticRenderFns }