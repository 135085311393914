var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        } else {
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    showPersonaPorCodigo(id) {
        return axios.get('api/Persona/showCodigoPersona?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    select2Persona(q) {
        return axios.get('api/Persona/select2?q=' + q);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    //IMPRIMIR FILIACION DE PERSONA
    printPFiliacion(id) {
        return axios.post('api/Persona/printPFiliacion?id=' + id);
    }

    //ROL

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //FUERZA

    showFuerza(id) {
        return axios.get('api/Fuerza/show?id=' + id);
    }

    indexFuerza() {
        return axios.defaults.baseURL + 'api/Fuerza/index';
    }

    listFuerza() {
        return axios.get('api/Fuerza/list');
    }

    storeFuerza(item) {
        return axios.post('api/Fuerza/store', item);
    }

    destroyFuerza(item) {
        return axios.post('api/Fuerza/destroy', item);
    }

    //Destino

    showDestino(id) {
        return axios.get('api/Destino/show?id=' + id);
    }

    index() {
        return axios.defaults.baseURL + 'api/Destino/index';
    }

    listDestino() {
        return axios.get('api/Destino/list');
    }

    listDestinoIndividual(item) {
        return axios.post('api/Destino/listindividual', item);
    }

    storeDestino(item) {
        return axios.post('api/Destino/store', item);
    }

    storeDestinoHistorial(item) {
        return axios.post('api/Destino/storehistorial', item);
    }

    destroyDestino(item) {
        return axios.post('api/Destino/destroy', item);
    }

    //DEPARTAMENTO

    showDepartamento(id) {
        return axios.get('api/Departamento/show?id=' + id);
    }

    indexDepartamento() {
        return axios.defaults.baseURL + 'api/Departamento/index';
    }

    listDepartamento() {
        return axios.get('api/Departamento/list');
    }

    storeDepartamento(item) {
        return axios.post('api/Departamento/store', item);
    }

    destroyDepartamento(item) {
        return axios.post('api/Departamento/destroy', item);
    }

    //ESPECIALIDAD

    showEspecialidad(id) {
        return axios.get('api/Especialidad/show?id=' + id);
    }

    indexEspecialidad() {
        return axios.defaults.baseURL + 'api/Especialidad/index';
    }

    listEspecialidad() {
        return axios.get('api/Especialidad/list');
    }

    storeEspecialidad(item) {
        return axios.post('api/Especialidad/store', item);
    }

    destroyEspecialidad(item) {
        return axios.post('api/Especialidad/destroy', item);
    }
    //Cargo

    showCargo(id) {
        return axios.get('api/Cargo/show?id=' + id);
    }

    indexCargo() {
        return axios.defaults.baseURL + 'api/Cargo/index';
    }

    listCargo() {
        return axios.get('api/Cargo/list');
    }

    storeCargo(item) {
        return axios.post('api/Cargo/store', item);
    }

    destroyCargo(item) {
        return axios.post('api/Cargo/destroy', item);
    }

    select2Cargo(q) {
        return axios.get('api/Cargo/select2?q=' + q);
    }
    //Reparticion

    showReparticion(id) {
        return axios.get('api/Reparticion/show?id=' + id);
    }

    indexReparticion() {
        return axios.defaults.baseURL + 'api/Reparticion/index';
    }

    listReparticion() {
        return axios.get('api/Reparticion/list');
    }

    storeReparticion(item) {
        return axios.post('api/Reparticion/store', item);
    }

    destroyReparticion(item) {
        return axios.post('api/Reparticion/destroy', item);
    }

    // select233Reparticion(q) {
    //     return axios.get('api/Reparticion/select233?q=' + q);
    // }
    //Escalafon

    showEscalafon(id) {
        return axios.get('api/Escalafon/show?id=' + id);
    }

    indexEscalafon() {
        return axios.defaults.baseURL + 'api/Escalafon/index';
    }

    listEscalafon() {
        return axios.get('api/Escalafon/list');
    }

    storeEscalafon(item) {
        return axios.post('api/Escalafon/store', item);
    }

    destroyEscalafon(item) {
        return axios.post('api/Escalafon/destroy', item);
    }
    //Grado

    showGrado(id) {
        return axios.get('api/Grado/show?id=' + id);
    }

    indexGrado() {
        return axios.defaults.baseURL + 'api/Grado/index';
    }

    listGrado() {
        return axios.get('api/Grado/list');
    }

    storeGrado(item) {
        return axios.post('api/Grado/store', item);
    }

    destroyGrado(item) {
        return axios.post('api/Grado/destroy', item);
    }

    select2Grado(q) {
        return axios.get('api/Grado/select2?q=' + q);
    }

    //Ascenso

    showAscenso(id) {
        return axios.get('api/Ascenso/show?id=' + id);
    }

    indexAscenso() {
        return axios.defaults.baseURL + 'api/Ascenso/index';
    }

    listAscenso() {
        return axios.get('api/Ascenso/list');
    }

    storeAscenso(item) {
        return axios.post('api/Ascenso/store', item);
    }

    destroyAscenso(item) {
        return axios.post('api/Ascenso/destroy', item);
    }

    //Sexo

    showSexo(id) {
        return axios.get('api/Sexo/show?id=' + id);
    }

    indexSexo() {
        return axios.defaults.baseURL + 'api/Sexo/index';
    }

    listSexo() {
        return axios.get('api/Sexo/list');
    }

    storeSexo(item) {
        return axios.post('api/Sexo/store', item);
    }

    destroySexo(item) {
        return axios.post('api/Sexo/destroy', item);
    }

    //GrupoSanguineo

    showGrupoSanguineo(id) {
        return axios.get('api/GrupoSanguineo/show?id=' + id);
    }

    indexGrupoSanguineo() {
        return axios.defaults.baseURL + 'api/GrupoSanguineo/index';
    }

    listGrupoSanguineo() {
        return axios.get('api/GrupoSanguineo/list');
    }

    storeGrupoSanguineo(item) {
        return axios.post('api/GrupoSanguineo/store', item);
    }

    destroyGrupoSanguineo(item) {
        return axios.post('api/GrupoSanguineo/destroy', item);
    }

    //EstadoCivil

    showEstadoCivil(id) {
        return axios.get('api/EstadoCivil/show?id=' + id);
    }

    indexEstadoCivil() {
        return axios.defaults.baseURL + 'api/EstadoCivil/index';
    }

    listEstadoCivil() {
        return axios.get('api/EstadoCivil/list');
    }

    storeEstadoCivil(item) {
        return axios.post('api/EstadoCivil/store', item);
    }

    destroyEstadoCivil(item) {
        return axios.post('api/EstadoCivil/destroy', item);
    }

    //Arma

    showArma(id) {
        return axios.get('api/Arma/show?id=' + id);
    }

    indexArma() {
        return axios.defaults.baseURL + 'api/Arma/index';
    }

    listArma() {
        return axios.get('api/Arma/list');
    }

    storeArma(item) {
        return axios.post('api/Arma/store', item);
    }

    destroyArma(item) {
        return axios.post('api/Arma/destroy', item);
    }

    //TipoMerito

    showTipoMerito(id) {
        return axios.get('api/TipoMerito/show?id=' + id);
    }

    indexTipoMerito() {
        return axios.defaults.baseURL + 'api/TipoMerito/index';
    }

    listTipoMerito() {
        return axios.get('api/TipoMerito/list');
    }

    storeTipoMerito(item) {
        return axios.post('api/TipoMerito/store', item);
    }

    destroyTipoMerito(item) {
        return axios.post('api/TipoMerito/destroy', item);
    } 
    //TipoArmamento

    showTipoArmamento(id) {
        return axios.get('api/TipoArmamento/show?id=' + id);
    }

    indexTipoArmamento() {
        return axios.defaults.baseURL + 'api/TipoArmamento/index';
    }

    listTipoArmamento() {
        return axios.get('api/TipoArmamento/list');
    }

    storeTipoArmamento(item) {
        return axios.post('api/TipoArmamento/store', item);
    }

    destroyTipoArmamento(item) {
        return axios.post('api/TipoArmamento/destroy', item);
    }
    //ArmamentoDotacion

    showArmamentoDotacion(id) {
        return axios.get('api/ArmamentoDotacion/show?id=' + id);
    }

    indexArmamentoDotacion() {
        return axios.defaults.baseURL + 'api/ArmamentoDotacion/index';
    }

    listArmamentoDotacion() {
        return axios.get('api/ArmamentoDotacion/list');
    }

    storeArmamentoDotacion(item) {
        return axios.post('api/ArmamentoDotacion/store', item);
    }

    destroyArmamentoDotacion(item) {
        return axios.post('api/ArmamentoDotacion/destroy', item);
    }
    

    //Tipo Capacitacion

    showTipoCapacitacion(id) {
        return axios.get('api/TipoCapacitacion/show?id=' + id);
    }

    indexTipoCapacitacion() {
        return axios.defaults.baseURL + 'api/TipoCapacitacion/index';
    }

    listTipoCapacitacion() {
        return axios.get('api/TipoCapacitacion/list');
    }

    storeTipoCapacitacion(item) {
        return axios.post('api/TipoCapacitacion/store', item);
    }

    destroyTipoCapacitacion(item) {
        return axios.post('api/TipoCapacitacion/destroy', item);
    }

    //Capacitacion Profesional

    showCapacitacionProfesional(id) {
        return axios.get('api/CapacitacionProfesional/show?id=' + id);
    }

    indexCapacitacionProfesional() {
        return axios.defaults.baseURL + 'api/CapacitacionProfesional/index';
    }

    listCapacitacionProfesional() {
        return axios.get('api/CapacitacionProfesional/list');
    }

    storeCapacitacionProfesional(item) {
        return axios.post('api/CapacitacionProfesional/store', item);
    }

    destroyCapacitacionProfesional(item) {
        return axios.post('api/CapacitacionProfesional/destroy', item);
    }

    //Merito

    showMerito(id) {
        return axios.get('api/Merito/show?id=' + id);
    }

    indexMerito() {
        return axios.defaults.baseURL + 'api/Merito/index';
    }

    listMerito() {
        return axios.get('api/Merito/list');
    }

    storeMerito(item) {
        return axios.post('api/Merito/store', item);
    }

    destroyMerito(item) {
        return axios.post('api/Merito/destroy', item);
    }
    //Tipo Familiar

    showTipoFamiliar(id) {
        return axios.get('api/TipoFamiliar/show?id=' + id);
    }

    indexTipoFamiliar() {
        return axios.defaults.baseURL + 'api/TipoFamiliar/index';
    }

    listTipoFamiliar() {
        return axios.get('api/TipoFamiliar/list');
    }

    storeTipoFamiliar(item) {
        return axios.post('api/TipoFamiliar/store', item);
    }

    destroyTipoFamiliar(item) {
        return axios.post('api/TipoFamiliar/destroy', item);
    }

    // Familiar

    showFamiliar(id) {
        return axios.get('api/Familiar/show?id=' + id);
    }

    indexFamiliar() {
        return axios.defaults.baseURL + 'api/Familiar/index';
    }

    indexNormalFamiliar() {
        return axios.defaults.baseURL + 'api/Familiar/indexNormal';
    }

    indexFamiliarPorPersona() {
        return axios.defaults.baseURL + 'api/Familiar/index';
    }

    indexDestinoPorPersona() {
        return axios.defaults.baseURL + 'api/Destino/indexPersona';
    }

    listFamiliarIndividual(item) {
        return axios.post('api/Familiar/listindividual', item);
    }
    
    listFamiliar() {
        return axios.get('api/Familiar/list');
    }
    
    listFamiliarlista(item) {
        return axios.get('api/Familiar/listfamiliar',{params:item});
    }

    storeFamiliar(item) {
        return axios.post('api/Familiar/store', item);
    }

    destroyFamiliar(item) {
        return axios.post('api/Familiar/destroy', item);
    }

    //Tipo Profesion

    showTipoProfesion(id) {
        return axios.get('api/TipoProfesion/show?id=' + id);
    }

    indexTipoProfesion() {
        return axios.defaults.baseURL + 'api/TipoProfesion/index';
    }

    listTipoProfesion() {
        return axios.get('api/TipoProfesion/list');
    }

    storeTipoProfesion(item) {
        return axios.post('api/TipoProfesion/store', item);
    }

    destroyTipoProfesion(item) {
        return axios.post('api/TipoProfesion/destroy', item);
    }

    // Profesion

    showProfesion(id) {
        return axios.get('api/Profesion/show?id=' + id);
    }

    indexProfesion() {
        return axios.defaults.baseURL + 'api/Profesion/index';
    }

    listProfesion() {
        return axios.get('api/Profesion/list');
    }

    storeProfesion(item) {
        return axios.post('api/Profesion/store', item);
    }

    destroyProfesion(item) {
        return axios.post('api/Profesion/destroy', item);
    }


     //EstadoDocumento

     showEstadoDocumento(id) {
        return axios.get('api/EstadoDocumento/show?id=' + id);
    }

    indexEstadoDocumento() {
        return axios.defaults.baseURL + 'api/EstadoDocumento/index';
    }

    listEstadoDocumento() {
        return axios.get('api/EstadoDocumento/list');
    }

    storeEstadoDocumento(item) {
        return axios.post('api/EstadoDocumento/store', item);
    }

    destroyEstadoDocumento(item) {
        return axios.post('api/EstadoDocumento/destroy', item);
    }

     //ConceptoSP

     showConceptoSP(id) {
        return axios.get('api/ConceptoSP/show?id=' + id);
    }

    indexConceptoSP() {
        return axios.defaults.baseURL + 'api/ConceptoSP/index';
    }

    listConceptoSP() {
        return axios.get('api/ConceptoSP/list');
    }

    storeConceptoSP(item) {
        return axios.post('api/ConceptoSP/store', item);
    }

    destroyConceptoSP(item) {
        return axios.post('api/ConceptoSP/destroy', item);
    }

     //ServicioPersonal

     showServicioPersonal(id) {
        return axios.get('api/ServicioPersonal/show?id=' + id);
    }

    indexServicioPersonal() {
        return axios.defaults.baseURL + 'api/ServicioPersonal/index';
    }

    indexServicioPersonalDestino() {
        return axios.defaults.baseURL + 'api/ServicioPersonal/indexDestino';
    }

    listServicioPersonal(item) {
        return axios.get('api/ServicioPersonal/list', item);
    }

    storeServicioPersonal(item) {
        return axios.post('api/ServicioPersonal/store', item);
    }

    destroyServicioPersonal(item) {
        return axios.post('api/ServicioPersonal/destroy', item);
    }

    //SituacionConcepto

    showSituacionConcepto(id) {
        return axios.get('api/SituacionConcepto/show?id=' + id);
    }

    indexSituacionConcepto() {
        return axios.defaults.baseURL + 'api/SituacionConcepto/index';
    }

    listSituacionConcepto(item) {
        return axios.get('api/SituacionConcepto/list', item);
    }

    storeSituacionConcepto(item) {
        return axios.post('api/SituacionConcepto/store', item);
    }

    destroySituacionConcepto(item) {
        return axios.post('api/ConceptoSP/destroy', item);
    }

     //Demerito

     showDemerito(id) {
        return axios.get('api/Demerito/show?id=' + id);
    }

    indexDemerito() {
        return axios.defaults.baseURL + 'api/Demerito/index';
    }

    listDemerito() {
        return axios.get('api/Demerito/list');
    }

    storeDemerito(item) {
        return axios.post('api/Demerito/store', item);
    }

    destroyDemerito(item) {
        return axios.post('api/Demerito/destroy', item);
    }

     //OrdenDeMerito

     showOrdenDeMerito(id) {
        return axios.get('api/OrdenDeMerito/show?id=' + id);
    }

    indexOrdenDeMerito() {
        return axios.defaults.baseURL + 'api/OrdenDeMerito/index';
    }

    listOrdenDeMerito() {
        return axios.get('api/OrdenDeMerito/list');
    }

    storeOrdenDeMerito(item) {
        return axios.post('api/OrdenDeMerito/store', item);
    }

    destroyOrdenDeMerito(item) {
        return axios.post('api/OrdenDeMerito/destroy', item);
    }

     //FojaConcepto

     showFojaConcepto(id) {
        return axios.get('api/FojaConcepto/show?id=' + id);
    }

    indexFojaConcepto() {
        return axios.defaults.baseURL + 'api/FojaConcepto/index';
    }

    listFojaConcepto() {
        return axios.get('api/FojaConcepto/list');
    }

    storeFojaConcepto(item) {
        return axios.post('api/FojaConcepto/store', item);
    }

    destroyFojaConcepto(item) {
        return axios.post('api/FojaConcepto/destroy', item);
    }

    //Lugar

    showLugar(id) {
        return axios.get('api/Lugar/show?id=' + id);
    }

    indexLugar() {
        return axios.defaults.baseURL + 'api/Lugar/index';
    }

    listLugar() {
        return axios.get('api/Lugar/list');
    }

    storeLugar(item) {
        return axios.post('api/Lugar/store', item);
    }

    destroyLugar(item) {
        return axios.post('api/Lugar/destroy', item);
    }

    //EstadoViatico

    showEstadoViatico(id) {
        return axios.get('api/EstadoViatico/show?id=' + id);
    }

    indexEstadoViatico() {
        return axios.defaults.baseURL + 'api/EstadoViatico/index';
    }

    listEstadoViatico() {
        return axios.get('api/EstadoViatico/list');
    }

    storeEstadoViatico(item) {
        return axios.post('api/EstadoViatico/store', item);
    }

    destroyEstadoViatico(item) {
        return axios.post('api/EstadoViatico/destroy', item);
    }

    //TipoViatico

    showTipoViatico(id) {
        return axios.get('api/TipoViatico/show?id=' + id);
    }

    indexTipoViatico() {
        return axios.defaults.baseURL + 'api/TipoViatico/index';
    }

    listTipoViatico() {
        return axios.get('api/TipoViatico/list');
    }

    storeTipoViatico(item) {
        return axios.post('api/TipoViatico/store', item);
    }

    destroyTipoViatico(item) {
        return axios.post('api/TipoViatico/destroy', item);
    }

    //Viatico

    showViatico(id) {
        return axios.get('api/Viatico/show?id=' + id);
    }

    indexViatico() {
        return axios.defaults.baseURL + 'api/Viatico/index';
    }

    listViatico() {
        return axios.get('api/Viatico/list');
    }

    storeViatico(item) {
        return axios.post('api/Viatico/store', item);
    }

    destroyViatico(item) {
        return axios.post('api/Viatico/destroy', item);
    }

    //CategoriaViatico

    showCategoriaViatico(id) {
        return axios.get('api/CategoriaViatico/show?id=' + id);
    }

    indexCategoriaViatico() {
        return axios.defaults.baseURL + 'api/CategoriaViatico/index';
    }

    listCategoriaViatico() {
        return axios.get('api/CategoriaViatico/list');
    }

    storeCategoriaViatico(item) {
        return axios.post('api/CategoriaViatico/store', item);
    }

    destroyCategoriaViatico(item) {
        return axios.post('api/CategoriaViatico/destroy', item);
    }

    //Pasaje

    showPasaje(id) {
        return axios.get('api/Pasaje/show?id=' + id);
    }

    indexPasaje() {
        return axios.defaults.baseURL + 'api/Pasaje/index';
    }

    listPasaje() {
        return axios.get('api/Pasaje/list');
    }

    storePasaje(item) {
        return axios.post('api/Pasaje/store', item);
    }

    destroyPasaje(item) {
        return axios.post('api/Pasaje/destroy', item);
    }

    //TipoPasaje

    showTipoPasaje(id) {
        return axios.get('api/TipoPasaje/show?id=' + id);
    }

    indexTipoPasaje() {
        return axios.defaults.baseURL + 'api/TipoPasaje/index';
    }

    listTipoPasaje() {
        return axios.get('api/TipoPasaje/list');
    }

    storeTipoPasaje(item) {
        return axios.post('api/TipoPasaje/store', item);
    }

    destroyTipoPasaje(item) {
        return axios.post('api/TipoPasaje/destroy', item);
    }

    //FleteAlmacenamiento

    showFleteAlmacenamiento(id) {
        return axios.get('api/FleteAlmacenamiento/show?id=' + id);
    }

    indexFleteAlmacenamiento() {
        return axios.defaults.baseURL + 'api/FleteAlmacenamiento/index';
    }

    listFleteAlmacenamiento() {
        return axios.get('api/FleteAlmacenamiento/list');
    }

    storeFleteAlmacenamiento(item) {
        return axios.post('api/FleteAlmacenamiento/store', item);
    }

    destroyFleteAlmacenamiento(item) {
        return axios.post('api/FleteAlmacenamiento/destroy', item);
    }

    //PersonaViatico

    showPersonaViatico(id) {
        return axios.get('api/PersonaViatico/show?id=' + id);
    }

    showConsultaPersonaViatico(id) {
        return axios.get('api/PersonaViatico/showconsulta?id=' + id);
    }

    indexPersonaViatico() {
        return axios.defaults.baseURL + 'api/PersonaViatico/index';
    }

    listPersonaViatico() {
        return axios.get('api/PersonaViatico/list');
    }

    storePersonaViatico(item) {
        return axios.post('api/PersonaViatico/store', item);
    }

    destroyPersonaViatico(item) {
        return axios.post('api/PersonaViatico/destroy', item);
    }

    imprimirPersonaViatico(id) {
        return axios.get('api/PersonaViatico/imprimir?id=' + id);
    }

    imprimirPersonaViaticoDos(id) {
        return axios.get('api/PersonaViatico/imprimirDos?ci=' + id);
    }

    imprimirPersonaViaticoPost(item) {
        return axios.post('api/PersonaViatico/imprimir', item);
    }

    //PersonaViatico

    generaReporte(item) {
        return axios.post('api/Reporte/genera', item);
    }

    exportexcel(){
        return axios.get('api/PersonaViatico/export');
    }

    select22Persona(item) {

        //console.log (item)
        return axios.get('api/Persona/select2?q=' + item);
   }

    select2Reparticion(q) {
        return axios.get('api/Reparticion/select2?q=' + q);
    }

    select2Pasaje(q) {
        return axios.get('api/Pasaje/select2?q=' + q);
    }

    //REPORTES SP GENERALES
    printPermanenteGral(item) {
        return axios.post('api/ServicioPersonal/printPermanenteGral', item);
    }

    printEventualGral(item) {
        return axios.post('api/ServicioPersonal/printEventualGral', item);
    }

    printAltasGral(item) {
        return axios.post('api/ServicioPersonal/printAltasGral', item);
    }

    printAsignacionGral(item) {
        return axios.post('api/ServicioPersonal/printAsignacionGral', item);
    }

    printLetrasGral(item) {
        return axios.post('api/ServicioPersonal/printLetrasGral', item);
    }

    printConclucionesGral(item) {
        return axios.post('api/ServicioPersonal/printConclucionesGral', item);
    }

    printBajasGral(item) {
        return axios.post('api/ServicioPersonal/printBajasGral', item);
    }

    printAscensosGral(item) {
        return axios.post('api/ServicioPersonal/printAscensosGral', item);
    }

    printVariosGral(item) {
        return axios.post('api/ServicioPersonal/printVariosGral', item);
    }

    printDestinosGral(item) {
        return axios.post('api/ServicioPersonal/printDestinosGral', item);
    }

    printCuadroAlteracionesGral(item) {
        return axios.post('api/ServicioPersonal/printCuadroAlteracionesGral',item);
    }


    //REPORTES SP INDIVIDUALES

    //PERMANENTES
    printPDaen(item) {
        return axios.post('api/ServicioPersonal/printPDaen', item);
    }

    printPDem(item) {
        return axios.post('api/ServicioPersonal/printPDem', item);
    }

    printPDepss(item) {
        return axios.post('api/ServicioPersonal/printPDepss', item);
    }

    printPDim(item) {
        return axios.post('api/ServicioPersonal/printPDim', item);
    }

    printPMagister(item) {
        return axios.post('api/ServicioPersonal/printPMagister', item);
    }


    //EVENTUALES
    printEFrontera(item) {
        return axios.post('api/ServicioPersonal/printEFrontera',item);
    }

    printEProfesional(item) {
        return axios.post('api/ServicioPersonal/printEProfesional',item);
    }

    printEProlibro(item) {
        return axios.post('api/ServicioPersonal/printEProlibro',item);
    }

    //ALTAS
    printAIniimm(item) {
        return axios.post('api/ServicioPersonal/printAIniimm' ,item);
    }

    printAInpc(item) {
        return axios.post('api/ServicioPersonal/printAInpc' ,item);
    }

    printAReipc(item) {
        return axios.post('api/ServicioPersonal/printAReipc' ,item);
    }

    printAReipm(item) {
        return axios.post('api/ServicioPersonal/printAReipm' ,item);
    }

    printAIncorporacion114(item) {
        return axios.post('api/ServicioPersonal/printAIncorporacion114' ,item);
    }

    printAIncorporacion118(item) {
        return axios.post('api/ServicioPersonal/printAIncorporacion118' ,item);
    }
    
    //ASIGNACIONES FAMILIARES
    printAFLactancia(item) {
        return axios.post('api/ServicioPersonal/printAFLactancia', item);
    }

    printAFNatalidad(item) {
        return axios.post('api/ServicioPersonal/printAFNatalidad', item);
    }

    printAFMortalidad(item) {
        return axios.post('api/ServicioPersonal/printAFMortalidad', item);
    }

    printAFPrenatal(item) {
        return axios.post('api/ServicioPersonal/printAFPrenatal', item);
    }

    printAFLactanciaConPaquete(item) {
        return axios.post('api/ServicioPersonal/printAFLactanciaConPaquete', item);
    }

    printAFPrenatalConPaquete(item) {
        return axios.post('api/ServicioPersonal/printAFPrenatalConPaquete', item);
    }

    //LETRAS
    printLLetraA(item) {
        return axios.post('api/ServicioPersonal/printLLetraA' , item);
    }

    printLLetraB(item) {
        return axios.post('api/ServicioPersonal/printLLetraB', item);
    }

    printLLetraC(item) {
        return axios.post('api/ServicioPersonal/printLLetraC', item);
    }

    printLLetraD(item) {
        return axios.post('api/ServicioPersonal/printLLetraD', item);
    }

    printLLetraEJM(item) {
        return axios.post('api/ServicioPersonal/printLLetraEJM', item);
    }

    printLLetraEJO(item) {
        return axios.post('api/ServicioPersonal/printLLetraEJO', item);
    }

    printLArt87(item) {
        return axios.post('api/ServicioPersonal/printLArt87', item);
    }
    printLArt114(item) {
        return axios.post('api/ServicioPersonal/printLArt114', item);
    }
    printLSinEfectoLetraEDispPJM(item) {
        return axios.post('api/ServicioPersonal/printLSinEfectoLetraEDispPJM', item);
    }
    

    //CONCLUSIONES    

    printCConclusionLetraB(item) {
        return axios.post('api/ServicioPersonal/printCConclusionLetraB' , item);
    }

    printCConclusionLetraC(item) {
        return axios.post('api/ServicioPersonal/printCConclusionLetraC', item);
    }

    printCConclusionLetraD(item) {
        return axios.post('api/ServicioPersonal/printCConclusionLetraD', item);
    }

    printCConclusionLetraEJM(item) {
        return axios.post('api/ServicioPersonal/printCConclusionLetraEJM', item);
    }

    printCConclusionLetraEJO(item) {
        return axios.post('api/ServicioPersonal/printCConclusionLetraEJO', item);
    }

    printCConclusionProLibro(item) {
        return axios.post('api/ServicioPersonal/printCConclusionProLibro', item);
    }

    printCConclusionCursoMil(item) {
        return axios.post('api/ServicioPersonal/printCConclusionCursoMil', item);
    }

    printCConclusionDestinoTemp(item) {
        return axios.post('api/ServicioPersonal/printCConclusionDestinoTemp', item);
    }

    //BAJAS
    printBExoneracion(item) {
        return axios.post('api/ServicioPersonal/printBExoneracion', item);
    }

    printBFallecido(item) {
        return axios.post('api/ServicioPersonal/printBFallecido', item);
    }

    printBJubilacion(item) {
        return axios.post('api/ServicioPersonal/printBJubilacion', item);
    }
    
    printBRetiro89(item) {
        return axios.post('api/ServicioPersonal/printBRetiro89', item);
    }

    printBRetiro90(item) {
        return axios.post('api/ServicioPersonal/printBRetiro90', item);
    }
    printBBajaTLM(item) {
        return axios.post('api/ServicioPersonal/printBBajaTLM', item);
    }
    printBBajaLT(item) {
        return axios.post('api/ServicioPersonal/printBBajaLT', item);
    }

    //ASCENSO
    printASOGA(item) {
        return axios.post('api/ServicioPersonal/printASOGA', item);
    }

    printASPosteriorOGA(item) {
        return axios.post('api/ServicioPersonal/printASPosteriorOGA', item);
    }

    printASPostumo(item) {
        return axios.post('api/ServicioPersonal/printASPostumo', item);
    }

    //VARIOS
    printVEscalafon(item) {
        return axios.post('api/ServicioPersonal/printVEscalafon', item);
    }
    
    printVModificacion(item) {
        return axios.post('api/ServicioPersonal/printVModificacion', item);
    }
    printVEdad(item) {
        return axios.post('api/ServicioPersonal/printVEdad', item);
    }

    //DESTINO
    printDOGD(item) {
        return axios.post('api/ServicioPersonal/printDOGD', item);
    }

    printDPosterior(item) {
        return axios.post('api/ServicioPersonal/printDPosterior', item);
    }

    printDRadicatoria(item) {
        return axios.post('api/ServicioPersonal/printDRadicatoria', item);
    }

    printDCurMil(item) {
        return axios.post('api/ServicioPersonal/printDCurMil', item);
    }

    printDTemporal(item) {
        return axios.post('api/ServicioPersonal/printDTemporal', item);
    }

    printDReservaAct(item) {
        return axios.post('api/ServicioPersonal/printDReservaAct', item);
    }
    printDPersPermServActEJTO(item) {
        return axios.post('api/ServicioPersonal/printDPersPermServActEJTO', item);
    }

    printDPosteriorAlfabetico(item) {
        return axios.post('api/ServicioPersonal/printDPosteriorAlfabetico', item);
    }

    //reporte cuadro de alteraciones independiente

    printReporteCuadro(item) {
        return axios.post('api/ServicioPersonal/printReporteCuadro', item);
    }


     //DASHBOARD DE SERVICIOS PERSONALES

     listCantidadGrado(item) {
        return axios.get('api/Dashboard/cantidadgrado', item);
    }
    
    listCantidadGradoa(item) {
        return axios.get('api/Dashboard/cantidadgradoa', item);
    }

    cantidadEfectivos() {
        return axios.get('api/Dashboard/cantidadEfectivos');
    }


    //Tramo

    showTramo(id) {
        return axios.get('api/Tramo/show?id=' + id);
    }

    indexTramo() {
        return axios.defaults.baseURL + 'api/Tramo/index';
    }

    listTramo() {
        return axios.get('api/Tramo/list');
    }

    storeTramo(item) {
        return axios.post('api/Tramo/store', item);
    }

    destroyTramo(item) {
        return axios.post('api/Tramo/destroy', item);
    }


    generarviaticogral(item) {
        return axios.post('api/PersonaViaticoGuest/generarviaticogral', item);
    }

    imprimirviaticodetallado() {
        return axios.get('api/PersonaViaticoGuest/imprimirviaticodetallado');
    }

     // Vacacion

     showVacacion(id) {
        return axios.get('api/Vacacion/show?id=' + id);
    }

    indexVacacion() {
        return axios.defaults.baseURL + 'api/Vacacion/index';
    }

    storeVacacion(item) {
        return axios.post('api/Vacacion/store', item);
    }

    destroyVacacion(item) {
        return axios.post('api/Vacacion/destroy', item);
    }

    list() {
        return axios.get('api/Vacacion/list');
    }

    listVacacion(item) {
        return axios.post('api/Vacacion/listvacacion', item);
    }

    listVacacionIndividual(item) {
        return axios.post('api/Vacacion/listindividual', item);
    }

    PersonaDiasutilizados(id) {
        return axios.get('api/Vacacion/diasutilizados?id=' + id);
    }
    

}