import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "VacacionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "VacacionPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexVacacion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'DiasVacacionTotal', name: 'v.DiasVacacionTotal', title: 'Dias Vacacion Total' },
                { data: 'DiasVacacion', name: 'v.DiasVacacion', title: 'DiasVacacion' },
                { data: 'Disponibles', name: 'v.Disponibles', title: 'Disponibles' },
                { data: 'Utilizados', name: 'v.Utilizados', title: 'Utilizados' },
                { data: 'Motivo', name: 'v.Motivo', title: 'Motivo' },
                { data: 'FechaDesde', name: 'v.FechaDesde', title: 'Fecha Desde' },
                { data: 'FechaHasta', name: 'v.FechaHasta', title: 'Fecha Hasta' },
                { data: 'Horas', name: 'v.Horas', title: 'Horas' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            vacacions: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            vacacion: {
                FechaDesde: '',
                FechaHasta: ''
            },
            personalogueada:{},
            diasutilizados: null,
            horasutilizados: null,
            horadia: 0,
            horasRestantes: 0,
            horas: [
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' },
                { value: '6', label: '6' }
              ]

        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.vacacion.Respaldo = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        newVacacion() {
            this.vacacion = {};
            this.$refs['frm-vacacion'].show();
            this.showpersonalogueada()
            this.showdiasutilizados()

        },
        showpersonalogueada() {
            this.isLoading=true;
            console.log (this.auth.id);
            this.ss.showPersona(this.auth.id).then(
                (result) => {
                    let response = result.data;
                    this.personalogueada = response.data;
                    console.log(this.personalogueada)
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        }, 
        
        showdiasutilizados() {
            this.isLoading=true;
            console.log ('este es el codigo persona q envio',this.auth.CodigoPersona);
            this.ss.PersonaDiasutilizados(this.auth.CodigoPersona).then(
                (result) => {
                    let response = result.data;
                    this.diasutilizados = response.data;
                    this.horasutilizados = response.data1;
                    this.horadia =  Math.floor(this.horasutilizados / 8);
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        }, 
        showVacacion(id) {
            this.isLoading = true;
            this.ss.showVacacion(id).then(
                (result) => {
                    let response = result.data;
                    this.vacacion = response.data;
                    this.$refs['view-vacacion'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editVacacion() {
            this.$refs['frm-vacacion'].show();
            this.$refs['view-vacacion'].hide();
        },
        cancelVacacion() {
            if (this.vacacion.id) {
                this.$refs['view-vacacion'].show();
            }
            this.$refs['frm-vacacion'].hide();
        },
        saveVacacion() {
            this.ss.storeVacacion(this.vacacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-vacacion'].hide();
                    this.$refs['datatable-vacacion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteVacacion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyVacacion(this.vacacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-vacacion'].hide();
                                this.$refs['datatable-vacacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Vacacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showVacacion(data);
            });
        }
    },
    computed: {
        calcularDiferenciaDeDias() {
            if (!this.vacacion.FechaDesde || !this.vacacion.FechaHasta) {
                return 0; 
            }
    
            const fechaDesde = new Date(this.vacacion.FechaDesde);
            const fechaHasta = new Date(this.vacacion.FechaHasta);
    
            const diferenciaDeTiempo = fechaHasta.getTime() - fechaDesde.getTime();
            const diferenciaDeDias = Math.ceil(diferenciaDeTiempo / (1000 * 60 * 60 * 24));
    
            return diferenciaDeDias;
        },
        calcularHorasRestantes() {
            let horasRestantes = this.horasutilizados;
    
            if (this.horasutilizados > 8) {
                horasRestantes -= 8;
            }
    
            if (this.horasutilizados > 16) {
                horasRestantes -= 8;
            }
    
            return horasRestantes;
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {

        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            // this.getEscalafon();
        }
    }
};
