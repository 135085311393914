import MainService from "@/services/MainService.js";

export default {
    name: "ModulosPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ModulosPage",
            ss: ss,
            auth: {},
            rols: [],
            kardexs: [],
            errorBag: {},
            kardex: {},
            
        };
    },
    methods: {
        CapacitacionProfesional(){
            console.log('aqui estoy');
            this.$refs['CapacitacionProfesionalPage'].index();
            //this.$refs['indexCapacitacionProfesional'].hide();
        },
        
    },
    
   
};
