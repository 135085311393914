var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register register-with-news-feed"},[_vm._m(0),_c('div',{staticClass:"right-content"},[_vm._m(1),_c('div',{staticClass:"register-content"},[_c('form',{staticClass:"margin-bottom-0",attrs:{"method":"POST"},on:{"submit":_vm.checkForm}},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_c('div',{staticClass:"m-t-20 m-b-40 p-b-40 text-inverse"},[_vm._v(" Already a member? Click "),_c('router-link',{attrs:{"to":"/user/login-v3"}},[_vm._v("here")]),_vm._v(" to login. ")],1),_c('hr'),_c('p',{staticClass:"text-center"},[_vm._v(" © Color Admin All Right Reserved 2020 ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-feed"},[_c('div',{staticClass:"news-image",staticStyle:{"background-image":"url(/assets/img/login-bg/login-bg-15.jpg)"}}),_c('div',{staticClass:"news-caption"},[_c('h4',{staticClass:"caption-title"},[_c('b',[_vm._v("Color")]),_vm._v(" Admin App")]),_c('p',[_vm._v(" As a Color Admin app administrator, you use the Color Admin console to manage your organization’s account, such as add new users, manage security settings, and turn on the services you want your team to access. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"register-header"},[_vm._v(" Sign Up "),_c('small',[_vm._v("Create your Color Admin Account. It’s free and always will be.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"control-label"},[_vm._v("Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-space-10"},[_c('div',{staticClass:"col-md-6 m-b-15"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"First name","required":""}})]),_c('div',{staticClass:"col-md-6 m-b-15"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Last name","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"control-label"},[_vm._v("Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-b-15"},[_c('div',{staticClass:"col-md-12"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Email address","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"control-label"},[_vm._v("Re-enter Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-b-15"},[_c('div',{staticClass:"col-md-12"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Re-enter email address","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"control-label"},[_vm._v("Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-b-15"},[_c('div',{staticClass:"col-md-12"},[_c('input',{staticClass:"form-control",attrs:{"type":"password","placeholder":"Password","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox checkbox-css m-b-30"},[_c('div',{staticClass:"checkbox checkbox-css m-b-30"},[_c('input',{attrs:{"type":"checkbox","id":"agreement_checkbox","value":""}}),_c('label',{attrs:{"for":"agreement_checkbox"}},[_vm._v(" By clicking Sign Up, you agree to our "),_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Terms")]),_vm._v(" and that you have read our "),_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Data Policy")]),_vm._v(", including our "),_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Cookie Use")]),_vm._v(". ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-buttons"},[_c('button',{staticClass:"btn btn-primary btn-block btn-lg",attrs:{"type":"submit"}},[_vm._v("Sign Up")])])
}]

export { render, staticRenderFns }