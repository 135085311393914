var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-md-4"},[_c('div',{staticClass:"widget widget-stats bg-success"},[_vm._m(1),_c('div',{staticClass:"stats-info"},[_c('h4',[_vm._v("TOTAL FILIACIÓN")]),_c('p',[_vm._v(_vm._s(_vm.cantidadtotal))])]),_vm._m(2)])]),_c('div',{staticClass:"col-xl-2 col-md-4"},[_c('div',{staticClass:"widget widget-stats bg-teal"},[_vm._m(3),_c('div',{staticClass:"stats-content"},[_c('div',{staticClass:"stats-title"},[_vm._v("TOTAL SERVICIO ACTIVO")]),_c('div',{staticClass:"stats-number"},[_vm._v(_vm._s(_vm.cantidadservicioactivo))]),_vm._m(4),_c('div',{staticClass:"stats-desc"},[_vm._v("Porcentaje "+_vm._s((((_vm.cantidadservicioactivo*100)/_vm.cantidadtotal).toFixed(2)))+" %")])])])]),_c('div',{staticClass:"col-xl-2 col-md-4"},[_c('div',{staticClass:"widget widget-stats bg-blue"},[_vm._m(5),_c('div',{staticClass:"stats-content"},[_c('div',{staticClass:"stats-title"},[_vm._v("TOTAL SERVICIO PASIVO")]),_c('div',{staticClass:"stats-number"},[_vm._v(_vm._s(_vm.cantidadserviciopasivo))]),_vm._m(6),_c('div',{staticClass:"stats-desc"},[_vm._v("Porcentaje "+_vm._s((((_vm.cantidadserviciopasivo*100)/_vm.cantidadtotal).toFixed(2)))+" %")])])])]),_c('div',{staticClass:"col-xl-2 col-md-4"},[_c('div',{staticClass:"widget widget-stats bg-indigo"},[_vm._m(7),_c('div',{staticClass:"stats-content"},[_c('div',{staticClass:"stats-title"},[_vm._v("TOTAL BAJAS DEL EJTO.")]),_c('div',{staticClass:"stats-number"},[_vm._v(_vm._s(_vm.cantidadbajasejto))]),_vm._m(8),_c('div',{staticClass:"stats-desc"},[_vm._v("Porcentaje "+_vm._s((((_vm.cantidadbajasejto*100)/_vm.cantidadtotal).toFixed(2)))+" %")])])])]),_vm._m(9)]),_c('div',{staticClass:"col-md-12"},[_c('panel',{attrs:{"title":"Cantidad de Grado"}},[_c('bar-chart',{staticClass:"height-sm",attrs:{"data":_vm.barChart.data,"options":_vm.barChart.options}})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page-header-title"},[_c('h4',{staticClass:"page-header"},[_c('strong',[_vm._v("BIENVENIDO(A) A TABLERO DE CONTROL SERVICIOS PERSONALES ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"stats-icon"},[_c('i',{staticClass:"fa fa-globe"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"stats-link"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("View Detail "),_c('i',{staticClass:"fa fa-arrow-alt-circle-right"})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"stats-icon stats-icon-lg"},[_c('i',{staticClass:"fa fa-users"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"stats-progress progress"},[_c('div',{staticClass:"progress-bar",staticStyle:{"width":"70.1%"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"stats-icon stats-icon-lg"},[_c('i',{staticClass:"fa fa-male"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"stats-progress progress"},[_c('div',{staticClass:"progress-bar",staticStyle:{"width":"40.5%"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"stats-icon stats-icon-lg"},[_c('i',{staticClass:"fa fa-user-times"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"stats-progress progress"},[_c('div',{staticClass:"progress-bar",staticStyle:{"width":"76.3%"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-xl-2 col-md-4"},[_c('div',{staticClass:"widget widget-stats bg-dark"},[_c('div',{staticClass:"stats-icon stats-icon-lg"},[_c('i',{staticClass:"fa fa-comment-alt fa-fw"})]),_c('div',{staticClass:"stats-content"},[_c('div',{staticClass:"stats-title"},[_vm._v("NEW COMMENTS")]),_c('div',{staticClass:"stats-number"},[_vm._v("3,988")]),_c('div',{staticClass:"stats-progress progress"},[_c('div',{staticClass:"progress-bar",staticStyle:{"width":"54.9%"}})]),_c('div',{staticClass:"stats-desc"},[_vm._v("Better than last week (54.9%)")])])])])
}]

export { render, staticRenderFns }