import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CapacitacionProfesionalPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CapacitacionProfesionalPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexCapacitacionProfesional(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'CapacitacionProfesional', name: 'CapacitacionProfesional', title: 'Capacitacion Profesional' },
                { data: 'Grado', name: 'Grado', title: 'Grado Academico' },
                //{ data: 'Unidad', name: 'Unidad', title: 'Unidad' },
                //{ data: 'Pais', name: 'Pais', title: 'Pais' },
                { data: 'TipoDocumento', name: 'TipoDocumento', title: 'Tipo de Documento' },
                { data: 'NivelOtorgado', name: 'NivelOtorgado', title: 'Nivel Otorgado' },
                { data: 'Profesion', name: 'Profesion', title: 'Profesion' },
                { data: 'TipoCapacitacion', name: 'TipoCapacitacion', title: 'Tipo de Capacitacion' },
                //{ data: 'Rol', name: 'Rol', title: 'Rol' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            personas: [],
            profesiones: [],
            especialidades: [],
            tiposCapacitacion: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            capacitacionProfesional: {},

        };
    },
    methods: {

        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getEspecialidad() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.especialidades = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getTipoCapacitacion() {
            this.ss.listTipoCapacitacion().then(
                (result) => {
                    let response = result.data;
                    this.tiposCapacitacion = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.capacitacionProfesional.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        newCapacitacionProfesional() {
            this.capacitacionProfesional = {};
            this.$refs['frm-capacitacionProfesional'].show();
            // console.log("hhhhola")
        },
        showCapacitacionProfesional(id) {
            this.isLoading = true;
            this.ss.showCapacitacionProfesional(id).then(
                (result) => {
                    let response = result.data;
                    this.capacitacionProfesional = response.data;
                    this.$refs['view-capacitacionProfesional'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editCapacitacionProfesional() {
            this.$refs['frm-capacitacionProfesional'].show();
            this.$refs['view-capacitacionProfesional'].hide();
        },
        cancelCapacitacionProfesional() {
            if (this.capacitacionProfesional.id) {
                this.$refs['view-capacitacionProfesional'].show();
            }
            this.$refs['frm-capacitacionProfesional'].hide();
        },
        saveCapacitacionProfesional() {
            this.ss.storeCapacitacionProfesional(this.capacitacionProfesional).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-capacitacionProfesional'].hide();
                    this.$refs['datatable-capacitacionProfesional'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteCapacitacionProfesional() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyCapacitacionProfesional(this.capacitacionProfesional)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-capacitacionProfesional'].hide();
                                this.$refs['datatable-capacitacionProfesional'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        draw() {
            window.$('.btn-datatable-CapacitacionProfesional').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCapacitacionProfesional(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {

        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getPersona();
            this.getTipoCapacitacion();
            this.getEspecialidad();
            
        }
    }
};
