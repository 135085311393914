import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PersonaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaPage",
            ss: ss,
            auth: {},
            personas: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            variables:{}
        };
    },
    methods: {
        
        newPersona() {
            this.persona = {};
            this.$refs['view-persona'].show();
            // console.log("hhhhola")
        },

        enviar() {
            console.log(this.variables)
            this.ss.generaReporte(this.variables).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    // this.$bvToast.toast(
                    //     response.msg,
                    //     {
                    //         title: 'Correcto',
                    //         variant: 'success',
                    //         autoHideDelay: 5000
                    //     }
                    // )
                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        exportexcel() {
            this.ss.exportexcel().then(
                (result) => {
                    let response = result.data
                    console.log(response)
                    var link = document.createElement("a");
                        // If you don't know the name or want to use
                        // the webserver default set name = ''
                        link.setAttribute('download', 'reporte.xlsx');
                        link.href = response;
                        link.target ='_blank'
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } 
    }
};
