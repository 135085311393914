import MainService from "@/services/MainService.js";
import BarChart from '@/components/vue-chartjs/BarChart'
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import axios from "axios";

window.$ = window.jQuery = require("jquery");

export default {
	components: {
		BarChart,
        dataTable,
        Loader
	},
	data () {
        let ss = new MainService();
		return {
            msg: "DashboardPage",
            ss: ss,
            auth: {},
			barChart: {
				data: {
					labels: [],
					datasets: [{
						label: 'Cantidad Grados',
						borderWidth: 2,
						borderColor: '#727cb6',
						backgroundColor: 'rgba(114, 124, 182, 0.3)',
						data: []
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false
				}
			},
            cantidadgrados: [],
			array:[],
			cantidadtotal:[],
			cantidadservicioactivo:[],
			cantidadserviciopasivo:[],
			cantidadbajasejto:[],
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
		};	
	},
    methods: {
        getlistGrado() {
            this.ss.listCantidadGrado().then(
                (result) => {
                    let response = result.data;
                    this.cantidadgrados = response.data;
                }
            ).catch(error => {
                console.log(error);
            }); 
        },
		getEstadisticaGrado(){
            this.ss.listCantidadGradoa().then(
                (result) => {
                    let array = [];
                    let response = result.data;
                    response.forEach(
                        element => array.push(element)       
                    );
                    this.array = array;
                }
            )
        },
		getCantidadesgeneralesfiliacion() {
			this.ss.cantidadEfectivos().then(
				(result) => {
					let response = result.data;
					this.cantidadtotal = response.data;
					this.cantidadservicioactivo = response.data1;
					this.cantidadserviciopasivo = response.data2;
					this.cantidadbajasejto = response.data3;
				}
			).catch(error => {
				console.log(error);
			});
		},
		fetchData() {
			axios.get('https://spdiafbackend.serv.emi.edu.bo/api/Dashboard/cantidadgradoa').then(
				response => {
					const data = response.data;
					console.log(data);	
					this.barChart.data.labels = data.map(item => item.Grado);
					this.barChart.data.datasets[0].data = data.map(item => item.count);				
					console.log(this.barChart.data.labels)	
				}
				).catch(error => {
					console.log(error);
				});
		},
	},
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getlistGrado();
            this.getEstadisticaGrado();
            this.getCantidadesgeneralesfiliacion();
			this.fetchData();
        }
    }
};
