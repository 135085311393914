import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "MeritoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MeritoPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexMerito(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'NumeroDocumento', name: 'NumeroDocumento', title: 'Número Documento' },
                { data: 'Merito', name: 'Merito', title: 'Mérito' },
                { data: 'Fecha', name: 'Fecha', title: 'Descripcion' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            Meritos: [],
            Merito:{},

            TipoMeritos: [],
            TipoMerito:{},

            Personas: [],
            Persona:{},

            /* rols: [],
            rol:{}, */
            
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            
        };
    },
    methods: {

       getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.Personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getTipoMerito() {
            this.ss.listTipoMerito().then(
                (result) => {
                    let response = result.data;
                    this.TipoMeritos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        loadFileDocumentoRespaldo(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.Merito.DocumentoRespaldo = result.data.data;
                            //this.saveLibro();
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        
        newMerito() {
            this.Merito = {};
            this.$refs['frm-Merito'].show();
            // console.log("hhhhola")
        },
        showMerito(id) {
            this.isLoading=true;
            this.ss.showMerito(id).then(
                (result) => {
                    let response = result.data;
                    this.Merito = response.data;
                    this.$refs['view-Merito'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editMerito() {
            this.$refs['frm-Merito'].show();
            this.$refs['view-Merito'].hide();
        },
        cancelMerito() {
            if (this.Merito.id) {
                this.$refs['view-Merito'].show();
            }
            this.$refs['frm-Merito'].hide();
        },
        saveMerito() {
            this.ss.storeMerito(this.Merito).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-Merito'].hide();
                    this.$refs['datatable-Merito'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteMerito() {
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyMerito(this.Merito)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-Merito'].hide();
                                this.$refs['datatable-Merito'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Merito').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showMerito(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            // this.getUnidadAcademica();
            this.getPersona();
            this.getTipoMerito();
        }
    }
};
