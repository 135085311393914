import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ArmamentoDotacionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ArmamentoDotacionPage",
            ss: ss,
            auth: {},
            

            ajax: {
                "url": ss.indexArmamentoDotacion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'ArmamentoDotacion', name: 'ArmamentoDotacion', title: 'Armamento de Dotacion' },
                { data: 'Persona', name: 'Persona', title: 'Persona' },
                { data: 'Descripcion', name: 'Descripcion', title: 'Descripcion' },
                // { data: 'Estado', name: 'Estado', title: 'Estado' },
                { data: 'Estado', name: 'Estado', title: 'Estado', render:function(data, type, row){
                    if (row.Estado==1){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i>';}
                    else{ return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>';}
                } },
                //{ data: 'Rol', name: 'Rol', title: 'Rol' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            rols: [],
            personas: [],
            tipoArmamentos: [],
            armamentoDotacions: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            armamentoDotacion: {},
            
        };
    },
    methods: {
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTipoArmamento() {
            this.ss.listTipoArmamento().then(
                (result) => {
                    //console.log('tipo armamaento');
                    let response = result.data;
                    this.tipoArmamentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.armamentoDotacion.Archivo = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        
        newArmamentoDotacion() {
            this.armamentoDotacion = {};
            this.$refs['frm-armamentoDotacion'].show();
            // console.log("hhhhola")
        },
        showArmamentoDotacion(id) {
           
            this.isLoading=true;
            this.ss.showArmamentoDotacion(id).then(
                (result) => {
                    let response = result.data;
                    this.armamentoDotacion = response.data;
                    console.log(this.armamentoDotacion);
                    this.$refs['view-armamentoDotacion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editArmamentoDotacion() {
            this.$refs['frm-armamentoDotacion'].show();
            this.$refs['view-armamentoDotacion'].hide();
        },
        cancelArmamentoDotacion() {
            if (this.armamentoDotacion.id) {
                this.$refs['view-armamentoDotacion'].show();
            }
            this.$refs['frm-armamentoDotacion'].hide();
        },
        saveArmamentoDotacion() {
            this.ss.storeArmamentoDotacion(this.armamentoDotacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-armamentoDotacion'].hide();
                    this.$refs['datatable-armamentoDotacion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteArmamentoDotacion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyArmamentoDotacion(this.armamentoDotacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-armamentoDotacion'].hide();
                                this.$refs['datatable-armamentoDotacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-ArmamentoDotacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showArmamentoDotacion(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            
            this.getPersona();
            this.getTipoArmamento();
            

        }
    }
};
