import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import moment from 'moment'

export default {
    name: "MenuPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MenuPage",
            ss: ss,
            auth: {},
            ajaxMenu: {
                "url": ss.indexServicioPersonal(),
                headers: ss.getToken(),
            },
            ajax: {
                "url": ss.indexPersona(),
                data: function (d) {
                    d.grupo = 1;
                },
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición' },
                { data: 'Especialidad', name: 'e.Especialidad', title: 'Especialidad' },
                { data: 'Grado', name: 'g.Grado', title: 'Grado' },
                { data: 'Arma', name: 'a.Arma', title: 'Arma' },
                { data: 'CodMinDef', name: 'p.CodMinDef', title: 'CodMinDef' },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'CI', name: 'p.CI', title: 'CI' },
                { data: 'FechaNacimiento', name: 'p.FechaNacimiento', title: 'Fecha Nacimiento',  render: function(data,type ,row)
                {
                    return moment(row.FechaNacimiento).format('DD/MM/YYYY')
                }},                
                { data: 'Sexo', name: 's.Sexo', title: 'Genero' },
                { data: 'email', name: 'p.email', title: 'Correo' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            columnsMenu: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                //{ data: 'Grupo', name: 'Grupo', title: 'Grupo' },
                { data: 'Persona', name: 'Persona', title: 'Persona' },
                { data: 'SituacionConcepto', name: 'SituacionConcepto', title: 'Situacion Concepto' },
                { data: 'EstadoDocumento', name: 'EstadoDocumento', title: 'Estado Documento' },
                { data: 'FechaKardex', name: 'FechaKardex', title: 'Fecha Kardex' },
                { data: 'Nro', name: 'Nro', title: 'Nro' },
                { data: 'Descripcion', name: 'Descripcion', title: 'Descripcion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rols: [],
            estadodocumentos: [],
            serviciopersonals: [],
            situacions: [],
            isLoading: false,
            isLoadingFile: false,
            moment: moment,
            errorBag: {},
            password: {},
            serviciopersonal: {},
            search: '',
            seccion: 0,
            documento: [],
            persona: {},
            titulo: 'General',
            dato:this.$route.query.grupo,
            documentoFields: [
                'Nro',
                {key:'Descripcion',label:'Descripción',sortable:true}, 
                {key:'FechaVigencia',label:'Fecha Vigencia',sortable:true}, 
                'Tipo', 
                /*{key: 'cantidad',label: 'Cantidad',variant: 'secondary',sortable: true,class:'centradito'}, */
                'Detalles']
        };
    },
    methods: {
        cargarDatatable(grupo){
            /*switch (grupo){
                case 1:
                    this.titulo='PERMANENTES'
                    break;
                default:
                    this.titulo='OTRO'
                    break;
            }*/
           // this.seccion=grupo;
            console.log("se aplican los filtros", grupo);
            this.ajaxMenu.data = function (d) {
                d.grupo = grupo;
            }
            this.$refs['datatable-menu'].reloadAjax(this.ajaxMenu);
            this.$refs['datatable-menu'].reload();

        },
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.serviciopersonal.Archivo = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getDocumento(grupo) {
            this.seccion=grupo;
            console.log('estoy aca', grupo);
            this.ss.listServicioPersonal({params:{'grupo':grupo,'persona':this.persona.id}}).then(
                (result) => {
                    let response = result.data;
                    this.documento = response.data;
                    this.getSituacionConcepto();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoDocumento() {
            this.ss.listEstadoDocumento().then(
                (result) => {
                    let response = result.data;
                    this.estadodocumentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getSituacionConcepto() {
            this.ss.listSituacionConcepto({params:{'grupo':this.seccion}}).then(
                (result) => {
                    let response = result.data;
                    this.situacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newServicioPersonal() {
            this.serviciopersonal = {};
            this.$refs['frm-serviciopersonal'].show();
            // console.log("hhhhola")
        },
        showServicioPersonal(id) {
            this.isLoading=true;
            this.ss.showServicioPersonal(id).then(
                (result) => {
                    let response = result.data;
                    this.serviciopersonal = response.data;
                    this.$refs['view-serviciopersonal'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editServicioPersonal() {
            this.$refs['frm-serviciopersonal'].show();
            this.$refs['view-serviciopersonal'].hide();
        },
        cancelServicioPersonal() {
            if (this.serviciopersonal.id) {
                this.$refs['view-serviciopersonal'].show();
            }
            this.$refs['frm-serviciopersonal'].hide();
        },
        aceptarPersona() {
            this.$refs['view-persona'].hide();
        },
        saveServicioPersonal() {
            this.serviciopersonal.Persona = this.persona.id;
            this.ss.storeServicioPersonal(this.serviciopersonal).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-serviciopersonal'].hide();

                    this.$refs.uno.refresh();
                   // this.$refs['datatable-persona'].reload();
                   this.getDocumento(this.seccion);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteServicioPersonal() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyServicioPersonal(this.serviciopersonal)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-serviciopersonal'].hide();
                                this.$refs['datatable-persona'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-serviciopersonal'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-serviciopersonal'].show();
        },
        changePassword() {
            this.password.ServicioPersonal = this.serviciopersonal.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-serviciopersonal'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        showPersona(id) {
            this.isLoading=true;
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.persona = response.data;
                    this.$refs['view-persona'].show();
                    this.isLoading=false;
                    this.getDocumento(1);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        draw() {
            window.$('.btn-datatable-Persona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersona(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },

    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getEstadoDocumento();
            //this.getSituacionConcepto();
        } 
        this.cargarDatatable(this.$route.query.grupo);
        this.dato=this.$route.query.grupo;
    }
};
