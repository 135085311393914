import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "FamiliarPage",
    data() {
        let ss = new MainService();
        return {
            msg: "FamiliarPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexNormalFamiliar(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'p.Persona', title: 'Persona Titular' },
                { data: 'TipoFamiliar', name: 'tf.TipoFamiliar', title: 'Tipo de Familiar' },
                { data: 'Profesion', name: 'po.Profesion', title: 'Profesion' },
                { data: 'Familiar', name: 'fa.Familiar', title: 'Familiar' },
                { data: 'CI', name: 'fa.CI', title: 'CI' },
                { data: 'Dpto', name: 'fa.Dpto', title: 'Dpto.' },
                { data: 'FechaNacimiento', name: 'fa.FechaNacimiento', title: 'Fecha de Nacimiento' },
                { data: 'Edad', name: 'fa.Edad', title: 'Edad' },
                { data: 'Verificado', name: 'fa.Verificado', title: 'Activo',searchable: false, width: '10%',
                 className: 'centradito',
                  render: function (data, type, row) {
                    if (row.Verificado == false) {
                      return '<i class="fa fa-ban text-danger"> Inactivo</i>';
                    } else {
                      return '<i class="fa fa-check text-success"> Activo</i>';
                    }
                  },
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            personas: [],
            profesion: [],
            tipoFamiliares: [],
            familiares: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            familiar: {},

            personafamiliar: {},
            personasSelect:[],
            placeholderPersona:"",
            selectedPersona: null,
            searchPersona:'',
            hasPerson:false

        };
    },
    methods: {

        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getProfesion() {
            this.ss.listProfesion().then(
                (result) => {
                    let response = result.data;
                    this.profesion = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        getTipoFamiliar() {
            this.ss.listTipoFamiliar().then(
                (result) => {
                    let response = result.data;
                    this.tipoFamiliares = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        newFamiliar() {
            this.familiar = {};
            this.$refs['frm-familiar'].show();
            // console.log("hhhhola")
        },
        showFamiliar(id) {
            this.familiar={};
            this.personafamiliar=[],
            this.isLoading = true;
            this.hasPerson = false;
            this.ss.showFamiliar(id).then(
                (result) => {
                    let response = result.data;
                    this.familiar = response.data;
                    if(this.familiar.persona){
                        this.hasPerson = true;
                    }
                    this.$refs['view-familiar'].show();
                    this.isLoading = false;
                    this.ss.showPersonaPorCodigo(this.familiar.Persona).then(
                        (result2) =>{
                            let response2 = result2.data;
                            console.log(response2);
                            this.actualizarPersonaFamiliar(response2);
                        }
                        )
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        actualizarPersonaFamiliar(item) {
            this.personafamiliar = [];
            this.isLoading=true;
            this.ss.listFamiliar(item.data).then(
                (result) => {
                    let response = result.data;
                    this.personafamiliar = response.data;
                    console.log("tam",this.personafamiliar.length);

                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editFamiliar() {
            this.$refs['frm-familiar'].show();
            this.$refs['view-familiar'].hide();
        },
        cancelFamiliar() {
            if (this.familiar.id) {
                this.$refs['view-familiar'].show();
            }
            this.$refs['frm-familiar'].hide();
        },
        saveFamiliar() {
            this.ss.storeFamiliar(this.familiar).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-familiar'].hide();
                    this.$refs['datatable-familiar'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteFamiliar() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyFamiliar(this.familiar)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-familiar'].hide();
                                this.$refs['datatable-familiar'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cleanPerson() {
            this.hasPerson = false;
        },
       
        draw() {
            window.$('.btn-datatable-Familiar').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showFamiliar(data);
            });
        }
    },
    watch: {
        searchPersona(q) {
            this.ss.select22Persona(q).then((res) => {
                if (res.data.success) {
                   
                    this.personas = res.data.items;
                                   
                } else {
                    this.personas = [];
                }
            })
        },
        selectedPersona(persona){
            console.log(persona)
            this.familiar.Persona = persona.CodigoPersona;
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {

        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getPersona();
            this.getTipoFamiliar()
            this.getProfesion()
        }
    }
};
