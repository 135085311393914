import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "GenerarViaticoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "GenerarViaticoPage",
            ss: ss,
            auth: {},
            personas: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            variables:{},
            viatico: {},
            pasaje: [],
            personasSelect:[],
            placeholderPersona:"",
            placeholderPasaje:"",
            selectedPersona: null,
            searchPersona:'',
            searchPasaje:'',
            hasPerson:false

        };
    },
    methods: {
        
        newPersona() {
            this.persona = {};
            this.$refs['view-persona'].show();
            // console.log("hhhhola")
        },

        enviar() {
            console.log(this.variables.id)
            this.ss.generarviaticogral(this.variables).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        imprimirviaticodetallado() {
            this.ss.imprimirviaticodetallado().then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
      
    },
    watch: {
        searchPersona(q) {
            this.ss.select22Persona(q).then((res) => {
                if (res.data.success) {
                   
                    this.personas = res.data.items;
                                   
                } else {
                    this.personas = [];
                }
            })
        },
        selectedPersona(persona){
            this.viatico.Persona = persona.id;
        },
        searchPasaje(q) {
            this.ss.select2Pasaje(q).then((res) => {
                if (res.data.success) {
                   
                    this.pasaje = res.data.items;
                                   
                } else {
                    this.pasaje = [];
                }
            })
        },

    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } 
    }
};
